<template>
  <v-container id="klases-kompetencijos" fluid>
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>

    <div justify="center" v-if="this.$store.state.isAuthenticated">
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">
            {{ select?.name }} klasės pasiekimai
          </div>
        </template>
        <v-row
          class="d-flex text-h6 text-md-h5 text-lg-h4 text-xl-h3 align-center"
        >
          <v-col cols="12" md="6" class="text-h6 d-flex justify-end">
            Pasirinkti klasę
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="select"
              :items="klase"
              item-text="name"
              item-value="id"
              label="Select"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </v-col>
        </v-row>
        <div class="display-1 font-weight-light">Vaikų sąrašas</div>
        <v-simple-table fixed-header dense>
          <template v-slot:default>
            <thead justify="center">
              <tr>
                <th>Eil. Nr.</th>
                <th>Vardas Pavardė</th>
                <th v-for="(title, y) in titles" :key="y" class="text-center">
                  {{ title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in getMokiniuAtsakymaiI" :key="i">
                <td>{{ 1 + i }}</td>
                <td>
                  {{ item?.mokinys }}
                </td>
                <td>
                  {{ item?.atsakymas5I }}
                </td>
                <td>
                  {{ getMokiniuAtsakymaiII[i].atsakymas5II }}
                </td>
                <td>
                  {{ item?.atsakymas6I }}
                </td>
                <td>
                  {{ getMokiniuAtsakymaiII[i].atsakymas6II }}
                </td>
                <td>
                  {{ item?.atsakymas7I }}
                </td>
                <td>
                  {{ getMokiniuAtsakymaiII[i].atsakymas7II }}
                </td>
                <td>
                  {{ item?.atsakymas8I }}
                </td>
                <td>
                  {{ getMokiniuAtsakymaiII[i].atsakymas8II }}
                </td>
              </tr>
              <tr>
                <th></th>
                <th>Klasės Vidurkis</th>
                <th v-for="(title, x) in period" :key="x">
                  {{ getVidurkis(title) }}
                </th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </base-material-card>
    </div>
  </v-container>
</template>

<script>
import baseMaterialCard from "@/components/base/MaterialCard.vue";
import loginSite from "@/components/LoginSite.vue";

export default {
  name: "manoklase-view",
  sum: 0,
  count: 0,
  components: {
    baseMaterialCard,
    loginSite,
  },
  data() {
    return {
      titles: [
        "5 - klasė-I",
        "5 - klasė-II",
        "6 - klasė-I",
        "6 - klasė-II",
        "7 - klasė-I",
        "7 - klasė-II",
        "8 - klasė-I",
        "8 - klasė-II",
      ],
      period: [
        "atsakymas5I",
        "atsakymas5II",
        "atsakymas6I",
        "atsakymas6II",
        "atsakymas7I",
        "atsakymas7II",
        "atsakymas8I",
        "atsakymas8II",
      ],
      select: this.$store.state.mokytojoKlase[0],
      klase: this.$store.state.mokytojoKlase,
      mokiniai: this.$store.state.mokiniai,
      valid: true,

      errors: [],
    };
  },

  computed: {
    spalva() {
      return this.$store.state.spalva;
    },

    getVidurkisI() {
      return isNaN(this.sum / this.count)
        ? "-"
        : (this.sum / this.count).toFixed(2);
    },

    getMokiniuAtsakymaiI() {
     
      return this.$store.getters.getKlaseAtsakymaiByKlaseI(this.select.id);
    },
    getMokiniuAtsakymaiII() {
      
      return this.$store.getters.getKlaseAtsakymaiByKlaseII(this.select.id);
    },
  },
  methods: {
    getVidurkis(laikotarpis) {
      let a = this.$store.getters.getKlaseAtsakymaiByKlaseI(this.select.id);
      let b = this.$store.getters.getKlaseAtsakymaiByKlaseII(this.select.id);
      let sum = 0;
      let count = 0;
      let klasesAtsakymai = [...a, ...b];
      klasesAtsakymai.forEach((element) => {
        if (element[laikotarpis] && element[laikotarpis] !== "-") {
          sum += parseFloat(element[laikotarpis]);
          count++;
        }
      });
      return isNaN(sum / count) ? "-" : (sum / count).toFixed(2);
    },
  },
};
</script>
