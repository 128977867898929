import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import FloatingVue from "floating-vue";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import("floating-vue/dist/style.css");
import("@/assets/editor.css");
// Vuetify's CSS styles
import "vuetify/dist/vuetify.min.css";
import "vue2-editor/dist/vue2-editor.css";

Vue.config.productionTip = false;
axios.defaults.baseURL = "https://kudirkietis.lt/api/";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/";

new Vue({
  router,
  axios,
  store,
  vuetify,
  VueApexCharts,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(FloatingVue);
// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
// Vue.use(VueCookies, { expires: '7d'})
//cookies
import VueCookies from "vue-cookies";
Vue.use(require("vue-cookies"));
Vue.use(VueCookies);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});
