<template>
  <v-container id="mokymosi-kaita" fluid>
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>

    <div
      v-if="
        this.$store.state.isAuthenticated &
        ((this.$store.state.userRole === 'STUDENT') ^
          (this.$store.state.userRole === 'ADMIN'))
      "
      class="mx-auto"
    >
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="display-1 font-weight-light">Mokymosi Kaita</div>
        </template>
        <v-row>
          <v-col>
            <apexchart
              ref="chart"
              :width="chartWidth"
              :options="options"
              :series="series"
            ></apexchart>
          </v-col>
          <v-col>
            <v-card class="mx-auto">
              <v-toolbar flat dense>
                <v-toolbar-title>
                  <span class="subheading">Siektino pažymio pasirinkimas</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-row>
                  <v-col>
                    <v-btn
                      v-tooltip.bottom="'Parodyti grafike'"
                      @click="updateChart"
                    >
                      <v-icon>mdi-update</v-icon>
                      Parodyti grafike
                    </v-btn>
                  </v-col>

                  <v-col>
                    <v-btn v-tooltip.bottom="'Saugoti'" @click="saveChart">
                      <v-icon>mdi-content-save-check</v-icon>
                      Saugoti
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>

              <v-card-text>
                <v-row class="mb-4" justify="space-between">
                  <v-col class="text-left">
                    <span class="text-h2 font-weight-light" v-text="bpm"></span>
                    <span class="subheading font-weight-light mr-1"
                      >Pažymys</span
                    >
                  </v-col>
                </v-row>

                <v-slider
                  v-model="bpm"
                  :color="color"
                  track-color="grey"
                  always-dirty
                  min="4"
                  max="10"
                  step="0.1"
                >
                  <template v-slot:prepend>
                    <v-icon :color="color" @click="decrement">
                      mdi-minus
                    </v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon :color="color" @click="increment">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-slider>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-simple-table fixed-header dense>
              <template v-slot:default>
                <thead justify="center">
                  <tr>
                    <th>Eil. Nr.</th>
                    <th>Pažymys</th>
                    <th>Pavadinimas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in pusmeciai" :key="i">
                    <td>{{ 1 + i }}</td>
                    <td>
                      {{ item.pazimys }}
                    </td>
                    <td>
                      {{ changeText(item.pazimys_uz) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </base-material-card>
    </div>

    <v-container fluid v-else>
      <v-row>
        <v-col>
          <p>Jūs nesate mokinys</p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import loginSite from "@/components/LoginSite.vue";
import baseMaterialCard from "@/components/base/MaterialCard.vue";

export default {
  name: "MokymosiKaitaview",
  components: {
    apexchart: VueApexCharts,
    loginSite,
    baseMaterialCard,
  },
  data() {
    return {
      bpm: 10,
      interval: 0.1,

      series: [
        {
          name: "Pusmečio vidurkis",
          type: "column",
          data: this.$store.state.pusmeciai,
        },
        {
          name: "Siektinas pažymys",
          type: "line",
          data: this.$store.state.pazymiai,
        },
      ],

      options: {
        plotOptions: {
          bar: {
            columnWidth: "20%",
          },
        },
        yaxis: {
          min: 3,
          max: 10,
          forceNiceScale: false,
        },
        theme: {
          palette: "palette4", // upto palette10forceNiceScale
        },
        chart: {
          height: 350,
          type: "line",
        },
        stroke: {
          width: [4, 4, 10, 10],
        },
        title: {
          text: "Mokymosi kaita",
        },
        dataLabels: {
          enabled: true,
        },
        labels: [
          "5-klasė-I pusm.",
          "5-klasė-II pusm.",
          "6-klasė-I pusm.",
          "6-klasė-II pusm.",
          "7-klasė-I pusm.",
          "7-klasė-II pusm.",
          "8-klasė-I pusm.",
          "8-klasė-II pusm.",
        ],
      },
    };
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
    chartWidth() {
      if (this.$vuetify.breakpoint.name == "xs") return 350;
      if (this.$vuetify.breakpoint.name == "sm") return 450;
      if (this.$vuetify.breakpoint.name == "md") return 500;
      if (this.$vuetify.breakpoint.name == "lg") return 600;
      return 700;
    },
    color() {
      if (this.bpm < 5) return "red";
      if (this.bpm < 7) return "green";
      if (this.bpm < 9) return "teal";
      if (this.bpm < 10) return "indigo";
      return "indigo";
    },
    pusmeciai() {
      return this.$store.state.userpazymiai;
    },
  },

  methods: {
    changeText(text) {
      if (text.startsWith("mano")) {
        text = text.replace("mano-", "");
        return (
          "Siektinas pažymys " +
          text.replace("- klasė-", "klasė ") +
          " pusmetis"
        );
      }
      return (
        "Pusmečio vidurkis " + text.replace("- klasė-", "klasė ") + " pusmetis"
      );
    },
    dabartinisPusmetis() {
      return this.$store.state.pirmasPusmetis ? "I" : "II";
    },
    saveChart() {
      const postData = {
        pazimys: this.bpm,
        pazimys_uz:
          "mano-" +
          this.$store.state.mokinioKlase +
          " - klasė-" +
          this.dabartinisPusmetis(),
      };
      let listOfPazimysUz = [];

      this.$store.state.userpazymiai.forEach((value) => {
        listOfPazimysUz.push(value["pazimys_uz"]);
      });

      if (listOfPazimysUz.includes(postData["pazimys_uz"])) {
        console.log("Jau yra->pazimys_uz", postData["pazimys_uz"]);
      } else {
        // console.log("this.series", this.series);
        this.updateChart();
        this.$store.dispatch("addPazimys", postData);
        console.log("postData --> ", postData);
      }
    },
    updateChart() {
      this.series[1].data.push(this.bpm);
      this.$refs.chart.updateSeries(this.series);
      this.series[1].data.pop();
    },
    decrement() {
      this.bpm = this.bpm - 0.1;
    },
    increment() {
      this.bpm = this.bpm + 0.1;
    },
  },
};
</script>
