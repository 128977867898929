<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10">
        <v-card class="elevation-6 mt-10">
          <v-window v-model="step">
            <v-window-item :value="1" :color="spalva">
              <v-row>
                <v-col cols="12" md="6">
                  <v-card-text
                    class="mt-12"
                    v-if="!this.$store.state.isAuthenticated"
                  >
                    <h2 class="text-center">Prisijunkite prie savo paskyros</h2>
                    <h4 class="text-center grey--text">
                      Prisijunkite prie savo paskyros,
                      <br />kad galėtumėte toliau peržiūrėti ir redaguoti savo
                      knygelę.
                    </h4>
                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="8">
                        <v-form
                          ref="form"
                          v-model="valid"
                          lazy-validation
                          @submit.prevent="submitForm"
                        >
                          <template>
                            <div class="text-center">
                              <!-- <v-progress-circular
                                indeterminate
                                color="primary"
                                v-show="this.$store.state.isLoading"
                              >
                              </v-progress-circular> -->
                            </div>
                          </template>
                          <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="El. paštas"
                            required
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            class="mt-16"
                          />
                          <v-text-field
                            v-model="password"
                            label="slaptažodis"
                            required
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show ? 'text' : 'password'"
                            hint="Mažiausiai 8 simboliai"
                            counter
                            @click:append="show = !show"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                          />
                          <v-row>
                            <!-- <v-col cols="6" sm="7">
                              <v-checkbox
                                label="Remember Me"
                                class="mt-n1"
                                color="blue"
                              >
                              </v-checkbox>
                            </v-col> -->
                            <v-col cols="6" sm="5">
                              <v-btn
                                small
                                text
                                :color="spalva"
                                to="/forgot-password"
                                >Priminti slaptažodį
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-btn :color="spalva" dark block type="submit"
                                >Prisijungti</v-btn
                              >
                            </v-col>
                          </v-row>

                          <v-alert
                            border="bottom"
                            colored-border
                            type="warning"
                            elevation="2"
                            v-if="errors.length"
                          >
                            <p v-for="error in errors" :key="error.id">
                              {{ error }}
                            </p>
                          </v-alert>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <!-- <v-card-text class="mt-12" v-else>
                    <h2 class="text-center">
                      <strong
                        v-html="'Esate prisijungę prie knygelės'"
                      ></strong>
                      <br />
                    </h2>
                    <h4 class="text-center grey--text">
                      Sveikinu Tave, šimtą nepakartojamų dienų praleidusį
                      penktos klasės suole. Tavo rankose yra nauja Džiaugsmų ir
                      rūpesčių knygelė, kuri lydės Tave ir Tavo pažangą visus
                      ketverius mokymosi metus. Šioje knygelėje galėsi
                      sutalpinti visus savo mokyklinius lūkesčius, pasiekimus
                      bei laimėjimus. Linkiu Tau atradimų kupinų metų
                      progimnazijos suole. Su meile Tavo Džiaugsmų ir rūpesčių
                      knygelė
                    </h4>
                  </v-card-text> -->
                </v-col>
                <v-col cols="12" md="6" :class="[spalva, 'rounded-bl-xl']">
                  <div style="text-align: center; padding: 180px 0">
                    <v-card-text class="white--text">
                      <h2 class="text-center">
                        Iškilo klausimų? Dar neturite paskyros?
                      </h2>
                      <h4 class="text-center">
                        Sutvarkykime viską, kad galėtumėte pradėti
                      </h4>
                    </v-card-text>
                    <div class="text-center">
                      <v-btn outlined dark @click="step++">Susisiekti</v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="2">
              <v-row>
                <v-col cols="12" md="6" :class="[spalva, 'rounded-br-xl']">
                  <div style="text-align: center; padding: 180px 0">
                    <v-card-text class="white--text">
                      <h2 class="text-center">Jau prisiregistravote?</h2>
                      <h4 class="text-center">
                        Prisijunkite prie savo paskyros,
                        <br />kad galėtumėte toliau peržiūrėti ir redaguoti savo
                        knygelę.
                      </h4>
                    </v-card-text>
                    <div class="text-center">
                      <v-btn outlined dark @click="step--">Prisijungti</v-btn>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="6">
                  <v-card-text class="mt-12">
                    <h4 class="text-center">Užduoti klausimą</h4>
                    <h6 class="text-center grey--text">
                      Čia galite užduoti klausimą administratoriui,
                      <br />
                      jei negalite prisijungti ar iškilo kitų klausimų.
                    </h6>
                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="8">
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="name"
                              label="Jūsų vardas"
                              outlined
                              dense
                              color="blue"
                              autocomplete="false"
                              class="mt-4"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="surname"
                              label="Pavardė"
                              outlined
                              dense
                              color="blue"
                              autocomplete="false"
                              class="mt-4"
                            />
                          </v-col>
                        </v-row>
                        <v-text-field
                          v-model="postEmail"
                          label="El. paštas"
                          outlined
                          dense
                          color="blue"
                          autocomplete="false"
                        />
                        <v-textarea
                          v-model="text"
                          label="Klausimo tekstas"
                          outlined
                          dense
                          color="blue"
                          autocomplete="false"
                        />
                        <v-row> </v-row>
                        <v-btn
                          :color="spalva"
                          dark
                          block
                          @click.stop.prevent="postQuestion"
                          >Susisiekti</v-btn
                        >
                        <v-alert
                          border="bottom"
                          colored-border
                          type="warning"
                          elevation="2"
                          v-if="errors.length"
                        >
                          <p v-for="error in errors" :key="error.id">
                            {{ error }}
                          </p>
                        </v-alert>
                        <v-card class="mx-auto" max-width="344">
                          <v-card-text class="mt-12" v-if="isSend">
                            <h4 class="text-center grey--text">
                              Klausimas nuo {{ name }} išsiųstas
                            </h4>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "loginSite",
  data() {
    return {
      isSend: false,
      step: 1,
      show: false,
      valid: true,
      emailRules: [
        (v) => !!v || "El. paštas yra privalomas",
        (v) => /.+@.+\..+/.test(v) || "El. paštas turi galioti",
      ],
      rules: {
        required: (value) => !!value || "Privalomas.",
        min: (v) => v.length >= 8 || "Mažiausiai 8 simboliai",
      },
      select: null,
      checkbox: false,
      email: "",
      password: "",
      errors: [],
      name: "",
      surname: "",
      postEmail: "",
      text: "",
    };
  },
  props: {
    source: String,
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
  },
  methods: {
    async postQuestion() {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      const postData = {
        name: this.name,
        surname: this.surname,
        email: this.postEmail,
        text: this.text,
      };
      await axios
        .post("/post-question/", postData)
        .then((response) => {
          console.log("post-question", response);

          this.isSend = true;
          setTimeout(
            () => (
              (this.isSend = false),
              (this.name = ""),
              (this.surname = ""),
              (this.postEmail = ""),
              (this.text = "")
            ),
            5000
          );
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },

    async submitForm() {
      this.$store.commit("setIsLoading", true);
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      this.$store.commit("removeToken");
      this.errors = [];

      const formData = {
        email: this.email.toLowerCase(),
        password: this.password,
      };
      console.log("Jungiasi--> ", this.email);
      await axios
        .post("/auth/token/login/", formData)
        .then((response) => {
          const token = response.data.auth_token;
          this.$store.commit("setToken", token);
          axios.defaults.headers.common["Authorization"] = "Token " + token;
          localStorage.setItem("token", token);

          if (this.$store.state.token) {
            console.log(
              "Login this.$store.state.isAuthenticated",
              this.$store.state.isAuthenticated
            );

            this.$store.dispatch("getUserInfo");
            if (this.$store.state.userRole == "STUDENT") {
              console.log("created STUDENT == ", this.$store.state.userRole);

              this.$store.dispatch("getKlausimai");
              this.$store.dispatch("getAtsakymai");
              this.$store.dispatch("getPazymiai");
              this.$store.dispatch("getMokinioKlase");
            }
          } else {
            axios.defaults.headers.common["Authorization"] = "";
          }
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
        });

      this.$store.commit("setIsLoading", false);
      if (this.errors.length) {
        console.log("Klaida", this.errors);
        // await this.$router.push("/");
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    resetPasswordByEmail() {
      this.$router.push("password-reset");
    },
  },
};
</script>
<style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}

.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
