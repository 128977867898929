<template>
  <v-container id="forgot-password" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card :color="spalva">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Pasikeisti slaptažodį</div>

            <div class="subtitle-1 font-weight-light">
              Įrašykite savo elektroninio pašto adresą ir Jums bus išsiųstas
              laiškas su slaptažodžio keitimo nuoroda
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Įrašykite savo elektroninio pašto adresą čia."
                    required
                    dense
                    autocomplete="false"
                    @keydown.enter.prevent="changePassword"
                  />
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn
                    :color="spalva"
                    class="mr-0"
                    @click.stop.prevent="changePassword"
                  >
                    Patvirtinti
                  </v-btn>
                </v-col>
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  v-if="errors.length"
                >
                  <p>
                    {{ errors[0] }}
                  </p>
                </v-alert>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import baseMaterialCard from "@/components/base/MaterialCard.vue";

import axios from "axios";

export default {
  name: "forgot-password-view",
  components: {
    baseMaterialCard,
  },
  data: () => ({
    step: 1,
    show: false,
    valid: true,
    emailRules: [
      (v) => !!v || "E-mail privalomas",
      (v) => /.+@.+\..+/.test(v) || "El. paštas turi galioti",
    ],

    select: null,
    checkbox: false,
    email: "",
    errors: [],
  }),
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
  },
  methods: {
    async changePassword() {
      this.errors = [];
      this.$store.commit("setIsLoading", true);

      const postData = {
        email: this.email.toLowerCase(),
      };
      await axios
        .post("/auth/users/reset_password/", postData)
        .then((response) => {
          this.$router.push("/");
          console.log("Password requested...", response);
          // this.email = "";
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push(
              "Kažkas nutiko netaip. Prašau, pabandykite dar kartą!"
            );
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);

      axios.defaults.headers.common["Authorization"] = "";
      this.$store.commit("removeToken");
      this.$store.commit("removeUserData");
      // this.$router.push("/");
    },
  },
};
</script>
