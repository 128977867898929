<template>
  <v-container id="home-view" fluid>
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>
    <div v-if="this.$store.state.isAuthenticated" class="mx-auto">
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">
            Čia Aš ... Dabar jau {{ userKlase }}
          </div>
        </template>
        <v-row>
          <v-card-text class="mt-12">
            <h2 class="text-center">
              {{ pages[0]?.title }}
              <br />
              <br />
            </h2>
            <div class="ql-editor">
              <div v-html="pages[0]?.content"></div>
            </div>
          </v-card-text>
        </v-row>
      </base-material-card>
    </div>
    <vue-editor v-if="false"></vue-editor>
  </v-container>
</template>

<script>
import loginSite from "@/components/LoginSite.vue";
import baseMaterialCard from "@/components/base/MaterialCard.vue";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    loginSite,
    baseMaterialCard,
    VueEditor,
  },
  name: "Home-view",
  data() {
    return {
      child: "",
      userId: this.$store.state.userId,
      rez: 0,
      row: null,
      errors: [],
      show: false,
      showInfo: false,
      seimos: this.$store.state.visosSeimos,
    };
  },
  computed: {
    pages() {
      return this.$store.getters.getPageByRole;
    },
    spalva() {
      return this.$store.state.spalva;
    },
    userKlase() {
      return this.$store.getters.kelintokas;
    },
  },
  methods: {},
};
</script>
<style></style>
