<template>
  <div class="mx-auto">
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>
    <v-container
      v-if="
        this.$store.state.isAuthenticated &
        ((this.$store.state.userRole === 'STUDENT') ^
          (this.$store.state.userRole === 'ADMIN'))
      "
      fluid
    >
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">
            Taigi aš ...
          </div>
        </template>
        <v-row>
          <v-col>
            <v-simple-table fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">5-I pus.</th>
                    <th class="text-left">5-II pus.</th>
                    <th class="text-left">Iš viso</th>
                    <th class="text-left">6-I pus.</th>
                    <th class="text-left">6-II pus.</th>
                    <th class="text-left">Iš viso</th>
                    <th class="text-left">7-I pus.</th>
                    <th class="text-left">7-II pus.</th>
                    <th class="text-left">Iš viso</th>
                    <th class="text-left">8-I pus.</th>
                    <th class="text-left">8-II pus.</th>
                    <th class="text-left">Iš viso</th>
                    <th class="text-left">Iš viso</th>
                  </tr>
                </thead>
                <tbody v-for="(categ, y) in categor" :key="y">
                  <tr>
                    <td class="font-weight-black">{{ categ }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-for="(item, i) in atsakymaiByKlase()" :key="i">
                    <template v-if="item.category == categ">
                      <td>
                        {{ item.klausimas.name }}
                      </td>
                      <td>
                        {{ item.atsakymas5I }}
                      </td>
                      <td>
                        {{ item.atsakymas5II }}
                      </td>
                      <td class="font-weight-black">
                        {{ item.total5() }}
                      </td>
                      <td>
                        {{ item.atsakymas6I }}
                      </td>
                      <td>
                        {{ item.atsakymas6II }}
                      </td>
                      <td class="font-weight-black">
                        {{ item.total6() }}
                      </td>
                      <td>
                        {{ item.atsakymas7I }}
                      </td>
                      <td>
                        {{ item.atsakymas7II }}
                      </td>
                      <td class="font-weight-black">
                        {{ item.total7() }}
                      </td>
                      <td>
                        {{ item.atsakymas8I }}
                      </td>
                      <td>
                        {{ item.atsakymas8II }}
                      </td>
                      <td class="font-weight-black">
                        {{ item.total8() }}
                      </td>
                      <td class="font-weight-black">
                        {{ item.total() }}
                      </td>
                    </template>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="font-weight-black">
                    <td>Iš viso</td>
                    <td>{{ vidurkis5I }}</td>
                    <td>{{ vidurkis5II }}</td>
                    <td>{{ atsakymuVidurkis5 }}</td>
                    <td>{{ vidurkis6I }}</td>
                    <td>{{ vidurkis6II }}</td>
                    <td>{{ atsakymuVidurkis6 }}</td>
                    <td>{{ vidurkis7I }}</td>
                    <td>{{ vidurkis7II }}</td>
                    <td>{{ atsakymuVidurkis7 }}</td>
                    <td>{{ vidurkis8I }}</td>
                    <td>{{ vidurkis8II }}</td>
                    <td>{{ atsakymuVidurkis8 }}</td>
                    <td>{{ atsakymuVidurkis }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </base-material-card>
    </v-container>
    <v-container fluid v-else>
      <v-row>
        <v-col>
          <p>Jūs nesate mokinys</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import loginSite from "@/components/LoginSite.vue";
import baseMaterialCard from "@/components/base/MaterialCard.vue";

export default {
  components: {
    loginSite,
    baseMaterialCard,
  },
  name: "taigi-as",
  value: "",

  data() {
    return {
      categor: [
        "Asmeninė kompetencija",
        "Pažinimo kompetencija",
        "Komunikavimo kompetencija",
        "Socialinė kompetencija",
        "Iniciatyvumo ir kūrybingumo kompetencija",
        "Mokėjimo mokytis kompetencija",
      ],

      rez: 0,
      row: null,
      useratsakymai: this.$store.getters.getResults,
    };
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
    vidurkis5I() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.atsakymas5I);
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    vidurkis5II() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.atsakymas5II);
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    vidurkis6I() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.atsakymas6I);
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    vidurkis6II() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.atsakymas6II);
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    vidurkis7I() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.atsakymas7I);
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    vidurkis7II() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.atsakymas7II);
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    vidurkis8I() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.atsakymas8I);
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    vidurkis8II() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.atsakymas8II);
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    atsakymuVidurkis5() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.total5());
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    atsakymuVidurkis6() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.total6());
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    atsakymuVidurkis7() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.total7());
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    atsakymuVidurkis8() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.total8());
        i += 1;
      });
      return (count / i).toFixed(2);
    },
    atsakymuVidurkis() {
      let count = 0;
      let i = 0;
      this.useratsakymai.forEach((el) => {
        count = count + parseFloat(el.total());
        i += 1;
      });
      return (count / i).toFixed(2);
    },
  },
  methods: {
    atsakymaiByKlase() {
      return this.$store.getters.getResults;
    },
  },
};
</script>
