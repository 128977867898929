<template>
  <v-container id="klase" fluid>
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>
    <div
      class="mx-auto"
      v-if="
        this.$store.state.isAuthenticated &
        (this.$store.state.userRole === 'STUDENT')
      "
      fluid
    >
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">
            Mano kompetencijos
          </div>
        </template>
        <v-row>
          <span v-if="pirmasPusmetis()">
            <v-col
              v-if="
                useratsakymailist?.length == klausimai?.length ? false : true
              "
            >
              <v-radio-group
                v-for="(klausimas, i) in klausimai"
                :key="i"
                v-model="radioGr[i]"
                :row="notMobile"
                v-show="!useratsakymailist?.includes(klausimas?.id)"
                @change="addAtsakymas(klausimas?.id, radioGr[i])"
              >
                <template v-slot:label>
                  <div>{{ klausimas?.name }}</div>
                </template>
                <v-radio
                  class="d-block"
                  v-for="(item, i) in atsakymai"
                  :key="i"
                  :value="item.id"
                >
                  <template v-slot:label>
                    <div>
                      <img
                        contain
                        :max-height="maxHeight"
                        :src="'/static/dist/img/' + item?.id + '.png'"
                        alt="smile"
                      />
                    </div>
                  </template>
                </v-radio>
                <p>{{ radioGr[i] }}</p>
              </v-radio-group>
            </v-col>
          </span>
          <span v-if="!pirmasPusmetis()">
            <v-col
              v-if="
                useratsakymailist?.length == klausimai?.length ? false : true
              "
            >
              <v-radio-group
                v-for="(klausimas, i) in klausimai"
                :key="i"
                v-model="radioGr[i]"
                :row="notMobile"
                v-show="!useratsakymailist?.includes(klausimas?.id)"
                @change="addAtsakymas(klausimas?.id, radioGr[i])"
              >
                <template v-slot:label>
                  <div>{{ klausimas?.name }}</div>
                </template>
                <v-row class="pt-2 pl-2 ma-0">
                  <v-radio
                    v-for="(item, i) in atsakymai"
                    :key="i"
                    :value="item?.id"
                  >
                    <template v-slot:label>
                      <div>
                        <img
                          contain
                          :max-height="maxHeight"
                          :src="'/static/dist/img/' + item?.id + '.png'"
                          alt="smile"
                        />
                      </div>
                    </template>
                  </v-radio>
                </v-row>
              </v-radio-group>
            </v-col>
          </span>

          <v-col>
            <v-simple-table fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Aš</th>
                    <th class="text-left">Pirmas Pusmetis</th>
                    <th class="text-left">Antras Pusmetis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(itemas, i) in useratsakymaiVisi" :key="i">
                    <td>{{ itemas.klausimas_detail?.name }}</td>
                    <td>
                      <img
                        contain
                        :max-height="maxHeight"
                        :src="
                          '/static/dist/img/' +
                          atsakymai[itemas.atsakymas - 1].id +
                          '.png'
                        "
                        alt="smile"
                      />
                    </td>
                    <td>
                      <img
                        contain
                        :max-height="maxHeight"
                        v-if="itemas.atsakymasII"
                        :src="
                          '/static/dist/img/' +
                          atsakymai[itemas.atsakymasII - 1]?.id +
                          '.png'
                        "
                        alt="smile"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </base-material-card>
    </div>
    <v-container fluid v-else>
      <v-row>
        <v-col>
          <p>Jūs nesate mokinys</p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import loginSite from "@/components/LoginSite.vue";
import baseMaterialCard from "@/components/base/MaterialCard.vue";

export default {
  components: {
    loginSite,
    baseMaterialCard,
  },
  name: "klase-view",
  value: "",

  data() {
    return {
      list: [],
      radioGr: [],
      row: null,
      apklausa: [],
    };
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
    notMobile() {
      if (this.$vuetify.breakpoint.name == "xs") return false;
      if (this.$vuetify.breakpoint.name == "sm") return false;
      if (this.$vuetify.breakpoint.name == "md") return true;
      if (this.$vuetify.breakpoint.name == "lg") return true;
      return true;
    },
    maxHeight() {
      if (this.$vuetify.breakpoint.name == "xs") return 30;
      if (this.$vuetify.breakpoint.name == "sm") return 30;
      if (this.$vuetify.breakpoint.name == "md") return 50;
      if (this.$vuetify.breakpoint.name == "lg") return 60;
      return 70;
    },
    useratsakymaiVisi() {
      return this.$store.getters.getUserAtsakymai(this.$route.params.kl);
    },
    atsakymai() {
      return this.$store.state.atsakymai;
    },
    klausimai() {
      return this.$store.state.klausimai;
    },
    useratsakymailist() {
      if (this.$store.state.pirmasPusmetis) {
        return this.$store.getters.getUserAtsakymaiListByKlaseI(
          this.$route.params.kl
        );
      } else {
        return this.$store.getters.getUserAtsakymaiListByKlaseII(
          this.$route.params.kl
        );
      }
    },
  },
  methods: {
    pirmasPusmetis() {
      return this.$store.state.pirmasPusmetis;
    },
    async addAtsakymas(kl, ats) {
      const atsakymas = {
        klausimas: kl,
        atsakymas: ats,
        class_value: this.$route.params.kl,
        is_first: this.pirmasPusmetis(),
      };
      await new Promise((r) => setTimeout(r, 500));
      if (this.$store.state.pirmasPusmetis) {
        this.$store.state.useratsakymailistI.push(kl);
      } else {
        this.$store.state.useratsakymailistII.push(kl);
      }
      this.$store.dispatch("addAnswer", atsakymas);
    },
  },
};
</script>

<style>
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0px;
}
</style>
