<template>
    <v-dialog v-model="dialog" persistent max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="error"
          elevation="2"
          icon
          x-small
        >
          <v-icon> mdi-account-plus-outline </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >Pakviesti Mokinio {{ source.name }} tėvą/motiną
          </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row
              v-for="(tev, i) in this.$store.getters.mokinioTevas(source.id)"
              :key="i"
            >
              Tevo/motinos el. paštas {{ tev.parent.email }}
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="parentName"
                  label="Vardas"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="parentSurname"
                  label="Pavardė"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="parentEmail"
                  :rules="emailRules"
                  label="Email"
                  required
                  dense
                  color="blue"
                  autocomplete="false"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="parentPassword"
                  label="Naujas slaptažodis"
                  required
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  hint="Mažiausiai 8 simboliai"
                  counter
                  @click:append="show = !show"
                  dense
                  color="blue"
                  autocomplete="false"
                  class="purple-input"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Uždaryti
          </v-btn>
          <v-btn color="blue darken-1" text @click="addParent(source)">
            Saugoti
          </v-btn>
          <v-alert
            border="bottom"
            colored-border
            type="warning"
            elevation="2"
            v-if="errors.length"
          >
            <p v-for="error in errors" :key="error.id">
              {{ error }}
            </p>
          </v-alert>
        </v-card-actions>
        <v-card-text v-if="showInfo">
          <h4 class="text-center grey--text">
            Mokinys {{ child }} uzregistruotas į klasę
          </h4>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "UserParentsDialog",
  data() {
    return {
      dialog: false,
      show: false,
      showInfo: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      parentEmail: "",
      parentPassword: "",
      errors: [],
      parentName: "",
      parentSurname: "",
    };
  },
  props: {
    source: Object,
  },

  methods: {
    mokTevas(x) {
      let tev = this.store.getters.mokinioTevas(x);
      console.log("mokinioTevas------------>", tev);
      return tev;
    },

    async addParent(mokinys) {
      console.log("addParent --->Starting", mokinys);

      this.$store.commit("setIsLoading", true);

      this.errors = [];
      const postData = {
        name: this.parentName,
        surname: this.parentSurname,
        email: this.parentEmail.toLowerCase(),
        password: this.parentPassword,
        role: "PARENTS",
      };
      await axios
        .post("/auth/users/", postData)
        .then((response) => {
          console.log("Parent added", response.data);
          this.parentregistered = true;
          setTimeout(() => (this.parentregistered = false), 10000);
          this.addToFamily(response.data.email, mokinys);
          this.dialog = false;
          this.showInfo = true;
          setTimeout(() => (this.showInfo = false), 10000);
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Erroras error.response.data",
              error.response.data.email
            );
            if (
              error.response.data.email ==
              "my user with this email address already exists."
            ) {
              this.addToFamily(postData.email, mokinys);
            }
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
          console.log("Erroras", this.errors);
        });
      this.$store.commit("setIsLoading", false);
    },
    async addToFamily(tevas, mokinys) {
      console.log("aaddToFamily --->Starting", tevas, mokinys);

      this.errors = [];
      const postData = {
        parent: tevas,
        child: mokinys.id,
      };
      await axios
        .post("/addToFamily/", postData)
        .then((response) => {
          console.log("addToFamily --->", response);
          this.dialog = false;
          this.showInfo = true;
          setTimeout(() => (this.showInfo = false), 10000);
        })
        .catch((error) => {
          if (error.response) {
            console.log("Erroras error.response.data", error.response.data);
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
    },
  },
};
</script>
<style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}

.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
