<template>
  <v-container id="mano-mokinys" fluid>
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>

    <!-- PARENTS PARENTS PARENTS -->
    <div
      justify="center"
      v-if="
        this.$store.state.isAuthenticated &
        (this.$store.state.userRole === 'PARENTS')
      "
    >
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="display-1 font-weight-light">
            Pasirinktas vaikas {{ child.name }}
          </div>
        </template>

        <v-row class="ma-0">
          <v-container fluid>
            <v-form>
              <v-row>
                <v-col cols="12" md="12">
                  <v-select
                    :items="getChilds"
                    v-model="child"
                    item-text="name"
                    item-value="id"
                    filled
                    label="Pasirinkti vaiką"
                    return-object
                  ></v-select>
                  <v-row>
                    <v-col>
                      Prašome, parašyti palinkėjimą, kurį Jūsų vaikas matys savo
                      el. pasiekimų knygelėje.
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-row v-for="(item, i) in klausimaiTevam" :key="i">
                        <v-row v-if="!komentarasMokList.includes(item.id)">
                          <v-textarea
                            class="pa-3"
                            v-model="text[i]"
                            :label="item.question"
                            outlined
                            rows="1"
                            row-height="5"
                            auto-grow
                          ></v-textarea>
                          <v-btn
                            dark
                            :color="spalva"
                            class="ma-3"
                            @click.stop.prevent="postComment(text[i], item?.id)"
                          >
                            Pateikti
                          </v-btn>
                        </v-row>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-for="(itemas, y) in klausimaiTevam" :key="y">
                    <div v-for="(item, i) in komentarasMok" :key="i">
                      <p
                        v-if="item['klausimas_tevam'] === itemas?.id"
                        class="text-left title"
                      >
                        <v-icon> mdi-hand-heart-outline </v-icon>
                        {{ itemas?.question }} {{ item?.text }}
                        <v-dialog v-model="item.dialog" width="500">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="error"
                              elevation="2"
                              icon
                              x-small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon> mdi-minus-circle-outline </v-icon>
                            </v-btn>
                          </template>

                          <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                              Ištrinti palinkėjimą
                            </v-card-title>

                            <v-card-text>
                              Ar tikrai norite ištrinti "{{ item.text }}"
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                :color="spalva"
                                text
                                @click="item.dialog = false"
                              >
                                Atsisakyti
                              </v-btn>
                              <v-btn
                                :color="spalva"
                                text
                                @click.stop.prevent="deleteComment(item?.id)"
                              >
                                Trinti
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </p>
                    </div>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-right">
                  <v-alert
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                    v-if="errors.length"
                  >
                    <p v-for="error in errors" :key="error.id">
                      {{ error }}
                    </p>
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
            <!-- <v-card-text>
              <h4 class="text-center grey--text">
                Mokinys {{ child }} uzregistruotas į klasę
              </h4>
            </v-card-text> -->
          </v-container>
        </v-row>
      </base-material-card>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import loginSite from "@/components/LoginSite.vue";
import baseMaterialCard from "@/components/base/MaterialCard.vue";

export default {
  components: {
    loginSite,
    baseMaterialCard,
  },
  name: "Mano-mokinys",

  data() {
    return {
      dialog: false,
      child: "",
      // parentId: this.$store.state.userId,
      // childs: this.$store.getters.TevoMokiniai(),
      text: [],
      rez: 0,
      row: null,
      errors: [],
      show: false,
      showInfo: false,
      // komentarai: this.getKomentaras(this.child),
      // seimos: this.$store.state.visosSeimos,
    };
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
    getChilds() {
      // console.log("getChilds------------>", this.$store.getters.TevoMokiniai());
      return this.$store.getters.TevoMokiniai();
    },
    klausimaiTevam() {
      // console.log(
      //   "klausimaiTevam ------------> this.child",
      //   this.child,
      //   this.$store.getters.getKlausimaiTevam(this.child)
      // );
      return this.$store.getters.getKlausimaiTevam(this.child.klase_val);
    },
    komentarasMok() {
      console.log(
        "komentarasMok ------------>",
        this.$store.getters.komentarasMokiniui(this.child.id)
      );
      return this.$store.getters.komentarasMokiniui(this.child.id);
    },
    komentarasMokList() {
      const copyList = [];
      this.komentarasMok.forEach((element) => {
        copyList.push(element.klausimas_tevam);
      });
      console.log("komentarasMokList +++++++++++++++>", copyList);
      return copyList;
    },
  },
  methods: {
    async deleteComment(id) {
      // console.log("deleteComment --->Starting", id);
      this.$store.commit("setIsLoading", true);
      this.errors = [];

      await axios
        .delete("/api/palinkejimai/" + id)
        .then(() => {
          // console.log("deleted Comment", response.data);
          this.$store.dispatch("getKomentarai");
          // ištrinti galima ir be kreipimosi i beckenda
          this.dialog = false;

          this.showInfo = true;
          setTimeout(() => (this.showInfo = false), 10000);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
    async postComment(text, id) {
      // console.log("addParent --->Starting", text, id);
      this.$store.commit("setIsLoading", true);
      this.errors = [];

      const postData = {
        parent: this.$store.state.userId,
        child: this.child.id,
        klausimas_tevam: id,
        text: text,
      };
      await axios
        .post("/api/palinkejimai/", postData)
        .then((response) => {
          // console.log("Komentaras added", response.data);
          this.$store.commit("updatePalinkejimai", response.data);

          this.showInfo = true;
          setTimeout(() => (this.showInfo = false), 10000);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
