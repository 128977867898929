<template>
  <v-app>
    <v-app-bar app :color="spalva" dark clipped-left>
      <v-app-bar-nav-icon
        class="d-flex d-md-none"
        @click.stop="open"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-btn href="\" target="_self" text>
          <v-img
            alt="Kudirka Logo"
            class="shrink mr-2"
            contain
            src="/static/dist/img/logo.png"
            transition="scale-transition"
            width="40"
          />
          <span
            class="mr-2 text-subtitle-2 text-md-subtitle-1 text-lg-subtitle-1 text-xl-subtitle-1"
            >Mano Pasiekimų knygelė</span
          >
        </v-btn>
      </div>
      <div v-if="role === 'STUDENT'" class="d-none d-md-flex">
        <v-col v-for="(item, i) in items" :key="i" class="mt-10">
          <v-btn
            icon
            @click.stop="
              dialog = true;
              src = item.src;
            "
          >
            <v-avatar size="90">
              <v-img :src="item.src" contain></v-img>
            </v-avatar>
          </v-btn>
        </v-col>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        class="d-none d-md-block"
        href="https://kudirka.radviliskis.lm.lt"
        target="_blank"
        text
      >
        <span class="mr-2">Mokyklos svetainė</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="grey lighten-3">
      <div
        class="ma-xs-0 pa-xs-0 ma-sm-1 pa-sm-1 ma-md-2 pa-md-2 ma-lg-3 pa-lg-3 ma-xl-3 pa-xl-3"
      >
        <v-card class="d-flex flex-column flex-sm-row mb-6">
          <v-card v-if="this.$store.state.isAuthenticated">
            <Navigation ref="modal"></Navigation>
          </v-card>
          <div
            class="d-flex justify-center mb-6"
            v-if="this.$store.state.isLoading"
          >
            <v-progress-circular
              class="d-flex flex-row mb-6"
              indeterminate
              :color="spalva"
            ></v-progress-circular>
          </div>
          <router-view />
        </v-card>
      </div>
      <v-card id="create" class="ma-15">
        <v-speed-dial
          v-model="fab"
          bottom
          right
          transition="slide-y-reverse-transition"
          direction="top"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" :color="spalva" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-cog-outline </v-icon>
            </v-btn>
          </template>
          <v-btn
            v-for="(spal, i) in spalvos"
            :key="i"
            fab
            dark
            small
            :color="spal.color"
            @click="change(spal.color)"
          >
            <v-icon>mdi-eyedropper-variant</v-icon>
          </v-btn>
        </v-speed-dial>
      </v-card>
    </v-main>
    <v-footer fixed padless class="d-none d-sm-flex">
      <v-card-text class="text-center pa-0">
        <v-btn
          class="mx-4"
          icon
          href="https://www.facebook.com/kudirka.radviliskis/"
        >
          <v-icon size="20px"> mdi-facebook </v-icon>
        </v-btn>

        {{ new Date().getFullYear() }} — <strong>Mano Knygelė</strong>
      </v-card-text>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import axios from "axios";

export default {
  components: { Navigation },
  name: "App",
  data() {
    return {
      items: [
        {
          color: "#1F7087",
          src: "/static/dist/img/badges/Asset 4.png",
          title: "Supermodel",
          artist: "Foster the People",
        },
        {
          color: "#952175",
          src: "/static/dist/img/badges/Asset 3.png",
          title: "Halcyon Days",
          artist:
            "Ellie Goulding rightrightright right right right right right right",
        },
        {
          color: "#1F7087",
          src: "/static/dist/img/badges/Asset 4.png",
          title: "Halcyon Days",
          artist:
            "Ellie Goulding rightrightright right right right right right right",
        },
        {
          color: "#952175",
          src: "/static/dist/img/badges/Asset 3.png",
          title: "Halcyon Days",
          artist:
            "Ellie Goulding rightrightright right right right right right right",
        },
      ],
      fab: "",
      spalvos: [
        { text: "indigo--text", color: "indigo" },

        { text: "indigo--text text--darken-3", color: "indigo darken-3" },

        {
          text: "orange--text",
          color: "orange",
        },

        { text: "orange--text text--darken-3", color: "orange darken-3" },

        { text: "primary--text", color: "primary" },

        {
          text: "secondary--text",
          color: "secondary",
        },

        {
          text: "success--text",
          color: "success",
        },
        {
          text: "info--text",
          color: "info",
        },
        {
          text: "warning--text",
          color: "warning",
        },

        { text: "error--text", color: "error" },
      ],
    };
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
    role() {
      return this.$store.state.userRole;
    },
  },
  methods: {
    notMobile() {
      if (this.$vuetify.breakpoint.name == "xs") return false;
      if (this.$vuetify.breakpoint.name == "sm") return false;
      if (this.$vuetify.breakpoint.name == "md") return true;
      if (this.$vuetify.breakpoint.name == "lg") return true;
      return true;
    },
    change(x) {
      this.$store.state.spalva = x;
      this.$cookies.set("color", x, -1);
    },
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
  beforeCreate() {
    console.log("beforeCreate Starting ... ");
    this.$store.commit("initializeStore");

    if (this.$store.state.token) {
      console.log(
        "this.$store.state.isAuthenticated",
        this.$store.state.isAuthenticated
      );
      axios.defaults.headers.common["Authorization"] =
        "Token " + this.$store.state.token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  created() {
    this.$store.commit("setSpalva");
    console.log("created Started ... ");

    if (this.$store.state.isAuthenticated) {
      console.log("created", this.$store.state.isAuthenticated);
      this.$store.dispatch("getUserInfo");
    }
    console.log("created STUDENT == ", this.$store.getters.userRole);
  },
};
</script>
<style scoped>
footer {
  z-index: 11;
}
#create .v-speed-dial {
  position: fixed;
  bottom: 30px;
  z-index: 12;
}

#create .v-btn--floatingxx {
  position: relative;
}
</style>
