<template>
  <div class="mx-auto">
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>
    <v-container
      v-if="
        this.$store.state.isAuthenticated &
        (this.$store.state.userRole === 'ADMIN')
      "
      fluid
    >
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">Čia Aš</div>
        </template>
        <v-switch
          :v-model="is_first"
          :label="is_first ? 'Pirmas pusmetis' : 'Antras pusmetis'"
          :color="spalva"
          :value="spalva"
          hint="Pakeisti pusmetį"
          @change="changePusmetis"
        ></v-switch>
        <template v-for="itemas in roles">
          <v-row v-for="item in page(itemas)" :key="item.id"
            ><v-col>
              <p>{{ itemas }}`o titulinis puslapis</p>
              <v-text-field label="Pavadinimas" v-model="item.title" required />
              <vue-editor v-model="item.content"></vue-editor>
              <v-btn
                :color="spalva"
                @click="addContent(item.id, item.title, item.content)"
              >
                Saugoti
              </v-btn>
              <v-alert dense type="success" dark v-if="info">
                <h4 class="text-center">Išsaugota</h4>
              </v-alert>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col> </v-col>
        </v-row>
      </base-material-card>
    </v-container>
  </div>
</template>

<script>
import loginSite from "@/components/LoginSite.vue";
import baseMaterialCard from "@/components/base/MaterialCard.vue";
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  components: {
    loginSite,
    baseMaterialCard,
    VueEditor,
  },

  name: "Home-view",
  data() {
    return {
      errors: [],
      pages: this.$store.state.pages,
      roles: ["ADMIN", "STUDENT", "TEACHER", "PARENT"],
      title: "",
      content: "",
      info: false,
    };
  },
  computed: {
    is_first() {
      return this.$store.state.pirmasPusmetis;
    },
    spalva() {
      return this.$store.state.spalva;
    },
  },
  methods: {
    changePusmetis() {
      this.$store.dispatch("changePusmetis", !this.$store.state.pirmasPusmetis);
    },
    addContent(id, title, content) {
      this.$store.commit("setIsLoading", true);
      const data = {
        title: title,
        content: content,
      };
      const idi = id == "undefined" ? "" : id;
      axios
        .patch("/api/page/" + idi, data)
        .then(() => {
          this.$store.dispatch.getPage;
          this.info = true;
          setTimeout(() => (this.info = false), 3000);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
    page(role) {
      const page = this.$store.getters.getPage(role);
      if (page.length > 0) {
        return page;
      } else {
        return [{ content: `${role} Tuscia....` }];
      }
    },
  },
};
</script>
