<template>
  <v-container id="user-profile" fluid tag="section">
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>
    <v-row justify="center" v-if="this.$store.state.isAuthenticated">
      <v-col cols="12" md="8">
        <base-material-card :color="spalva">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Redaguoti profilį</div>

            <div class="subtitle-1 font-weight-light">
              Papildyti savo profilio informaciją
            </div>
          </template>
          <div class="subtitle-1 font-weight-light">Pasikeisti slaptažodį</div>
          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="password"
                    label="Naujas slaptažodis"
                    required
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    hint="Mažiausiai 8 simboliai"
                    counter
                    @click:append="show = !show"
                    dense
                    color="primary"
                    autocomplete="false"
                    class="purple-input"
                  />
                  <v-text-field
                    v-model="current_password"
                    label="Senas slaptažodis"
                    required
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    dense
                    color="primary"
                    autocomplete="false"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12" md="12">
                  <v-btn
                    type="submit"
                    :color="spalva"
                    class="mr-0"
                    @click.stop.prevent="changePassword"
                  >
                    Keisti slaptažodį
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-form @submit.prevent="editUser">
            <v-container class="py-0">
              <div class="subtitle-1 font-weight-light">
                Redaguoti Vardą ir Pavardę
              </div>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Vardas"
                    class="purple-input"
                    v-model="name"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    label="Pavardė"
                    class="purple-input"
                    v-model="surname"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-alert
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                    v-if="errors.length"
                  >
                    <p v-for="error in errors" :key="error.id">
                      {{ error }}
                    </p>
                  </v-alert>
                  <v-btn
                    type="submit"
                    :color="spalva"
                    class="mr-0"
                    @click.prevent="editUser"
                  >
                    Saugoti pakeitimus
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn :color="spalva" class="mr-0" @click="toggleShow">
                    Atnaujinti nuotrauką
                  </v-btn>
                  <my-upload
                    langType="en"
                    field="img"
                    @crop-success="cropSuccess"
                    @crop-upload-success="cropUploadSuccess"
                    @crop-upload-fail="cropUploadFail"
                    v-model="showImput"
                    :width="200"
                    :height="200"
                    :params="params"
                    :headers="headers"
                    img-format="png"
                  ></my-upload>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4">
        <base-material-card
          class="v-card-profile"
          :avatar="this.$store.getters.userImage"
          image
          text="Apie mane"
          :color="spalva"
        >
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              {{ this.$store.getters.userRole }}
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
              {{ this.$store.state.userName }}
              {{ this.$store.state.userSurname }}
            </h4>

            <p class="font-weight-light grey--text">
              Atsidūrus vandenyje privalai plaukti, kitaip nuskęsi. Gyvenime
              lygiai taip pat: jame reikia plaukti, kai pavargsti mokėti
              pailsėti, paplaukti ant nugaros, pasisukinėti vandenyje... Bet jei
              visai nieko nedarysi - grimsi į dugną. (Menininkė Nomeda
              Marčėnaitė)
            </p>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import myUpload from "vue-image-crop-upload/upload-2.vue";
import baseMaterialCard from "@/components/base/MaterialCard.vue";

import loginSite from "@/components/LoginSite.vue";

import axios from "axios";

export default {
  name: "profile-view",
  components: {
    baseMaterialCard,

    loginSite,
    "my-upload": myUpload,
  },
  data() {
    return {
      radioGr: "",
      spalvos: [
        { text: "indigo--text", color: "indigo" },

        { text: "indigo--text text--darken-3", color: "indigo darken-3" },

        {
          text: "orange--text",
          color: "orange",
        },

        { text: "orange--text text--darken-3", color: "orange darken-3" },

        { text: "primary--text", color: "primary" },

        {
          text: "secondary--text",
          color: "secondary",
        },

        {
          text: "success--text",
          color: "success",
        },
        {
          text: "info--text",
          color: "info",
        },
        {
          text: "warning--text",
          color: "warning",
        },

        { text: "error--text", color: "error" },
      ],
      params: {
        // token: this.token(),
        name: "avatar",
      },
      headers: {
        Authorization: "Token " + this.$store.state.token,
      },
      step: 1,
      show: false,
      showImput: false,
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail privalomas",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      rules: {
        required: (value) => !!value || "Privalomas",
        min: (v) => v.length >= 8 || "Min 8 simboliai",
      },
      select: null,
      checkbox: false,
      email: "",
      password: "",
      name: this.$store.state.userName,
      surname: this.$store.state.userSurname,
      current_password: "",
      errors: [],
    };
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
  },
  methods: {
    notMobile() {
      if (this.$vuetify.breakpoint.name == "xs") return false;
      if (this.$vuetify.breakpoint.name == "sm") return false;
      if (this.$vuetify.breakpoint.name == "md") return true;
      if (this.$vuetify.breakpoint.name == "lg") return true;
      return true;
    },
    change(spalva) {
      this.$store.state.spalva = spalva;
    },
    async editUser(postData) {
      postData = {
        name: this.name,
        surname: this.surname,
      };
      this.errors = [];
      this.$store.commit("setIsLoading", true);

      await axios
        .patch("/auth/users/me/", postData)
        .then((response) => {
          console.log("edit Name Surname", response.data);
          this.$store.state.userName = response.data.name;
          this.$store.state.userSurname = response.data.surname;
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
    toggleShow() {
      this.showImput = !this.showImput;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      console.log("-------- crop success --------");
      let urlas = "";
      if (this.$store.state.userRole == "TEACHER") {
        urlas = "/api/teatcherprofile/";
      } else {
        urlas = "/api/studentprofile/";
      }
      // console.log("payload-->", payload);
      // console.log("imgDataUrl-->", imgDataUrl);

      axios
        .put(
          urlas +
            this.$store.state.userId +
            "/profile/" +
            this.$store.state.userProfileId +
            "/",
          {
            user: this.$store.state.userId,
            // img: atob(payload),
            img: imgDataUrl,
            // img: Buffer.from(payload, "base64"),
          }
        )
        .then((response) => {
          // location.reload();
          console.log("Responsas-->", response.data.img);
          console.log("Responsas-->", response.data.img.toString());
          // this.imgUrl = response.data.img.toString();
        })
        .catch(function (error) {
          // console.log("imgDataUrl-->", imgDataUrl);
          console.log("Fieldas-->", field);

          console.log(error);
        });
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },

    async addSpalva(x, y) {
      const postData = {
        current_password: x,
        new_password: y,
      };
      await axios
        .post("/", postData)
        .then((response) => {
          console.log("Password changed", response);
          (this.current_password = ""), (this.password = "");
        })
        .catch((error) => {
          console.log("Erroras", JSON.stringify(error));
        });
    },
    async changePassword() {
      const postData = {
        current_password: this.current_password,
        new_password: this.password,
      };
      await axios
        .post("/auth/users/set_password/", postData)
        .then((response) => {
          console.log("Password changed", response);
          (this.current_password = ""), (this.password = "");
        })
        .catch((error) => {
          console.log("Erroras", JSON.stringify(error));
        });
      axios.defaults.headers.common["Authorization"] = "";
      this.$store.commit("removeToken");
      this.$store.commit("removeUserData");
      this.$router.push("/");
    },
  },
};
</script>
<style>
.v-avatar {
  z-index: 10;
}
</style>
