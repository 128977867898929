<template>
  <!-- class="d-none d-sm-flex"
  :class="{ active: drawer }" -->
  <v-navigation-drawer
    :expand-on-hover="!enabled"
    mini-variant-width="80"
    v-model="drawer"
    mobile-breakpoint="960"
    disable-route-watcher
    app
    clipped
    class="pt-sm-2 pt-md-3 pt-lg-4 pt-xl-5"
  >
    <v-toolbar :color="spalva" dark>
      <v-toolbar-title>{{ this.$store.getters.userRole }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-show="enabled" icon @click.stop.prevent="enabled = !enabled">
        <v-icon>mdi-pin-outline</v-icon>
      </v-btn>
      <v-btn v-show="!enabled" icon @click.stop.prevent="enabled = !enabled">
        <v-icon>mdi-pin-off-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list nav>
      <v-list-item
        link
        to="/profile"
        class="px-2"
        v-tooltip.right="'Profilio Redagavimas'"
      >
        <v-list-item-avatar left size="80">
          <v-img
            lazy-src="/media/uploads/avatar.png"
            :src="this.$store.getters.userImage"
          />
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ this.$store.state.userName }}
            {{ this.$store.state.userSurname }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ this.$store.state.userEmail }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ this.$store.state.mokinioKlasePav }} - klasė
          </v-list-item-subtitle>
          <v-list-item-subtitle> Dabar: 2023-2024 m. m. </v-list-item-subtitle>

          <v-list-item-subtitle class="ml-8">
            {{ pirmasPusmetis() }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-btn
              text
              @click.stop.prevent="logout"
              v-show="this.$store.state.isAuthenticated"
            >
              Atsijungti
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list v-if="role === 'STUDENT'" nav dense class="mb-7">
      <v-list-item
        v-for="(item, i) in studentItems"
        :key="i"
        :disabled="item.klase <= mokinioKlase ? false : true"
        :to="!item.sub ? item.to : item.to + item.klase"
      >
        <template v-if="!item.sub">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </template>

        <v-list-group
          v-if="item.sub"
          sub-group
          color="black"
          :ripple="false"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="([title, icon, link], i) in submenu"
            :key="i"
            :to="link + item.klase"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list-item>
    </v-list>

    <v-list v-if="role === 'TEACHER'" nav dense>
      <v-list-item
        link
        v-for="(item, i) in teacherItems"
        :key="i"
        :to="item.to"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list v-if="role === 'PARENTS'" nav dense>
      <v-list-item
        link
        v-for="(item, i) in parentstItems"
        :key="i"
        :to="item.to"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list v-if="role === 'ADMIN'" nav dense>
      <v-list-item link v-for="(item, i) in adminItems" :key="i" :to="item.to">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import axios from "axios";

export default {
  name: "Navigation-menu",
  data() {
    return {
      submenu: [
        ["Tėvai apie mane", "mdi-account-multiple-outline", "/apie-mane/"],
        ["Mano kompetencijos", "mdi-cog-outline", "/klase/"],
      ],
      show: true,
      value: null,
      enabled: true,
      drawer: null,
      row: null,
      studentItems: [
        {
          text: "Pradžia",
          icon: "mdi-home-heart",
          to: "/",
          klase: 5,
          sub: false,
        },
        {
          text: "Mokymosi kaita",
          icon: "mdi-finance",
          to: "/mokymosi-kaita",
          klase: 5,
          sub: false,
        },
        {
          text: "Penkta klasė",
          icon: "mdi-numeric-5-box-multiple",
          to: "/klase/",
          klase: 5,
          sub: true,
        },
        {
          text: "Šešta klasė",
          icon: "mdi-numeric-6-box-multiple",
          to: "/klase/",
          klase: 6,
          sub: true,
        },
        {
          text: "Septinta klasė",
          icon: "mdi-numeric-7-box-multiple",
          to: "/klase/",
          klase: 7,
          sub: true,
        },
        {
          text: "Aštunta klasė",
          icon: "mdi-numeric-8-box-multiple",
          to: "/klase/",
          klase: 8,
          sub: true,
        },
        {
          text: "Taigi, Aš...",
          icon: "mdi-head-heart-outline",
          to: "/taigi-as",
          klase: 5,
          sub: false,
        },
        {
          text: "Pasiekimai",
          icon: "mdi-medal-outline",
          to: "/pasiekimai",
          klase: 5,
          sub: false,
        },
      ],
      teacherItems: [
        {
          text: "Pradžia",
          icon: "mdi-home-heart",
          to: "/",
        },
        {
          text: "Mano klasė",
          icon: "mdi-google-classroom",
          to: "/mano-klase",
        },
        {
          text: "Klasės pasiekimai",
          icon: "mdi-medal-outline",
          to: "/klases-pasiekimai",
        },
        {
          text: "Klasės kompetencijos",
          icon: "mdi-head-heart-outline",
          to: "/klases-kompetencijos",
        },
      ],
      parentstItems: [
        {
          text: "Pradžia",
          icon: "mdi-home-heart",
          to: "/",
        },
        {
          text: "Mano mokinys",
          icon: "mdi-medal-outline",
          to: "/mano-mokinys",
        },
      ],
      adminItems: [
        {
          text: "Pradžia",
          icon: "mdi-home-heart",
          to: "/admin",
        },
        {
          text: "Nustatymai",
          icon: "mdi-numeric-5-box-multiple",
          to: "/settings",
        },
      ],
    };
  },
  methods: {
    open() {
      this.drawer = true;
    },
    close() {
      this.drawer = false;
    },
    pirmasPusmetis() {
      if (this.$store.state.pirmasPusmetis) {
        return "I pusmetis";
      } else {
        return "II pusmetis";
      }
    },
    async logout() {
      this.dialog = true;
      await axios
        .post("/auth/token/logout/")
        .then((response) => {
          console.log("Logged out", response);
        })
        .catch((error) => {
          console.log("Erroras", JSON.stringify(error));
        });
      axios.defaults.headers.common["Authorization"] = "";
      this.$store.commit("removeToken");
      this.$store.commit("removeUserData");
      this.$router.push("/");
    },
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
    role() {
      return this.$store.state.userRole;
    },
    mokinioKlase() {
      return this.$store.state.mokinioKlase;
    },
  },
  // props: {
  //   isOn: Boolean,
  // },
};
</script>
<style>
.v-list-group__header {
  padding-left: 0px !important;
}
.v-list-group--sub-group.v-list-group--active
  .v-list-item__icon.v-list-group__header__prepend-icon
  .v-icon {
  transform: rotate(-360deg) !important;
}
.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action.v-list-group--sub-group
  > .v-list-group__items
  > .v-list-item {
  padding-left: 10px !important;
}
</style>
