<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="error"
          elevation="2"
          icon
          x-small
        >
          <v-icon> mdi-account-school-outline </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >Mokinio {{ source.name }} {{ source.surname }} pusmečių vidurkiai
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-simple-table fixed-header dense>
              <template v-slot:default>
                <thead justify="center">
                  <tr>
                    <th>Pažymys</th>
                    <th>Pažymys už</th>
                    <th>Ištrinti</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(paz, i) in getmokinioPazymiai(source.id)"
                    :key="i"
                  >
                    <td>{{ paz.pazimys }}</td>
                    <td>{{ paz.pazimys_uz }}</td>
                    <td>
                      <v-dialog v-model="paz.delDialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="error"
                            elevation="2"
                            icon
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon> mdi-minus-circle-outline </v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            Ištrinti pažimį
                          </v-card-title>

                          <v-card-text>
                            Ar tikrai norite ištrinti {{ paz?.pazimys }} už
                            {{ paz?.pazimys_uz }}
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="paz.delDialog = false"
                            >
                              Atsisakyti
                            </v-btn>
                            <v-btn
                              color="primary"
                              text
                              @click.stop.prevent="removePaz(paz.url)"
                            >
                              Trinti
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="pazimys"
                  label="Pažymys*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="pazimys_uz"
                  :items="[
                    ('5 - klasė-I', '5 - klasė-I'),
                    ('5 - klasė-II', '5 - klasė-II'),
                    ('6 - klasė-I', '6 - klasė-I'),
                    ('6 - klasė-II', '6 - klasė-II'),
                    ('7 - klasė-I', '7 - klasė-I'),
                    ('7 - klasė-II', '7 - klasė-II'),
                    ('8 - klasė-I', '8 - klasė-I'),
                    ('8 - klasė-II', '8 - klasė-II'),
                  ]"
                  label="Laikotarpis"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-alert dense type="info" dark v-if="info">
          <h4 class="text-center">Ištrinta</h4>
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Uždaryti
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="editUserPazymiai(source.id)"
          >
            Saugoti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "UserDialog",
  data() {
    return {
      info: false,
      pazimys: "",
      pazimys_uz: "",
      dialog: false,
      isSend: false,
      step: 1,
      show: false,
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      select: null,
      checkbox: false,
      email: "",
      password: "",
      errors: [],
      name: "",
      surname: "",
      postEmail: "",
      text: "",
    };
  },
  props: {
    source: Object,
  },

  methods: {
    getmokinioPazymiai(id) {
      // console.log(
      //   "PAZYMIAI------------>",
      //   this.$store.getters.mokinioPazymiai(id)
      // );

      return this.$store.getters.mokinioPazymiai(id);
    },

    async editUserPazymiai(url) {
      this.errors = [];

      this.$store.commit("setIsLoading", true);
      const postData = {
        mokinys: url,
        pazimys: this.pazimys,
        pazimys_uz: this.pazimys_uz,
      };
      console.log("put-pazimys", postData);

      await axios
        .post("/api/pazymiai/", postData)
        .then((response) => {
          console.log("put-pazimys", response);
          this.$store.dispatch("getPazymiai");
          // this.isSend = true;
          // setTimeout(() => (this.isSend = false), 5000);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
          console.log("Erroras", this.errors);
        });
      this.$store.commit("setIsLoading", false);
    },

    async removePaz(url) {
      console.log("delete PAZIMYS --->Starting", url);
      this.$store.commit("setIsLoading", true);
      this.errors = [];

      await axios
        .delete(url)
        .then((response) => {
          console.log("deleted PAZIMYS", response.data);
          this.info = true;
          setTimeout(() => (this.info = false), 3000);
          this.$store.dispatch("getPazymiai");
          // ištrinti galima ir be kreipimosi i beckenda
          // this.dialog = true;

          this.showInfo = true;
          setTimeout(() => (this.showInfo = false), 10000);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    resetPasswordByEmail() {
      this.$router.push("password-reset");
    },
  },
};
</script>
<style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}

.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
