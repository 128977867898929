<template>
  <v-container id="reset-password" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card :color="spalva">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Keisti slaptažodį</div>
          </template>
          <v-form @submit.prevent="changePassword">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="password"
                    label="Naujas slaptažodis"
                    required
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    hint="Mažiausiai 8 simboliai"
                    counter
                    @click:append="show = !show"
                    dense
                    color="blue"
                    autocomplete="false"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                    type="submit"
                    :color="spalva"
                    class="mr-0"
                    @click.stop.prevent="changePassword"
                  >
                    Keisti slaptažodį
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import baseMaterialCard from "@/components/base/MaterialCard.vue";

export default {
  name: "change-password-view",
  components: {
    baseMaterialCard,
  },
  data: () => ({
    show: false,
    valid: true,

    rules: {
      required: (value) => !!value || "Privalomas",
      min: (v) => v.length >= 8 || "Min 8 simboliai",
    },
    password: "",
    errors: [],
  }),
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
  },
  methods: {
    async changePassword() {
      const postData = {
        uid: this.$route.params.uid,
        token: this.$route.params.token,
        new_password: this.password,
      };
      console.log("Password changed data", postData);

      await axios
        .post("/auth/users/reset_password_confirm/", postData)
        .then((response) => {
          console.log("Password changed", response);
          (this.current_password = ""), (this.password = "");
        })
        .catch((error) => {
          console.log("Erroras", JSON.stringify(error));
        });
      axios.defaults.headers.common["Authorization"] = "";
      this.$store.commit("removeToken");
      this.$store.commit("removeUserData");
      this.$router.push("/");
    },
  },
};
</script>
