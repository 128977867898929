import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import MokymosiView from "../views/MokymosiKaitaView.vue";
import TaigiView from "../views/TaigiView.vue";
import klaseView from "../views/klaseView.vue";
import ProfileView from "../views/ProfileView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import ActivatedView from "../views/ActivatedView.vue";
import ManoKlaseView from "../views/ManoKlaseView.vue";
import ManoMokinysView from "../views/ManoMokinysView.vue";
import SettingsView from "../views/SettingsView.vue";
import AdminView from "../views/AdminView.vue";
import ApieManeView from "../views/ApieManeView.vue";
import PasiekimaiView from "../views/PasiekimaiView.vue";
import KlasesPasiekimaiView from "../views/KlasesPasiekimaiView.vue";
import KlasesKompetencijosView from "../views/KlasesKompetencijosView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home-view",
    component: HomeView,
  },
  {
    path: "/mokymosi-kaita",
    name: "mokymosi-kaita",
    component: MokymosiView,
  },
  {
    path: "/taigi-as",
    name: "taigi-as",
    component: TaigiView,
  },
  {
    path: "/klase/:kl",
    name: "klase",
    component: klaseView,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
  },
  {
    path: "/activate-password/:uid/:token",
    name: "reset-password",
    component: ResetPasswordView,
  },
  {
    path: "/activate/:uid/:token",
    name: "activate-password",
    component: ActivatedView,
  },
  {
    path: "/mano-klase",
    name: "mano-klase",
    component: ManoKlaseView,
  },
  {
    path: "/mano-mokinys",
    name: "mano-mokinys",
    component: ManoMokinysView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
  },
  {
    path: "/apie-mane/:kl",
    name: "apie-mane",
    component: ApieManeView,
  },
  {
    path: "/pasiekimai",
    name: "pasiekimai-view",
    component: PasiekimaiView,
  },
  {
    path: "/klases-pasiekimai",
    name: "klases-pasiekimai",
    component: KlasesPasiekimaiView,
  },
  {
    path: "/klases-kompetencijos",
    name: "klases-kompetencijos",
    component: KlasesKompetencijosView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
