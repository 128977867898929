<template>
  <v-container id="activate" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Aktyvacija</div>
          </template>
          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click.stop.prevent="activateUser"
                  >
                    Aktyvuoti paskyrą
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import baseMaterialCard from "@/components/base/MaterialCard.vue";

export default {
  name: "change-password-view",
  components: {
    baseMaterialCard,
  },
  data: () => ({}),

  methods: {
    async activateUser() {
      const postData = {
        uid: this.$route.params.uid,
        token: this.$route.params.token,
      };
      console.log("Activate data", postData);

      await axios
        .post("/auth/users/activation/", postData)
        .then((response) => {
          console.log("Activated", response);
        })
        .catch((error) => {
          console.log("Erroras", JSON.stringify(error));
        });
      axios.defaults.headers.common["Authorization"] = "";
      this.$store.commit("removeToken");
      this.$store.commit("removeUserData");
      this.$router.push("/");
    },
  },
};
</script>
