<template>
  <v-container id="klases-pasiekimai" fluid>
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>

    <div justify="center" v-if="this.$store.state.isAuthenticated">
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">
            {{ select?.name }} klasės pasiekimai
          </div>
        </template>
        <v-row
          class="d-flex text-h6 text-md-h5 text-lg-h4 text-xl-h3 align-center"
        >
          <v-col cols="12" md="6" class="text-h6 d-flex justify-end">
            Pasirinkti klasę
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="select"
              :items="klase"
              item-text="name"
              item-value="id"
              label="Select"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </v-col>
        </v-row>
        <div class="display-1 font-weight-light">Vaikų sąrašas</div>
        <v-simple-table fixed-header dense>
          <template v-slot:default>
            <thead justify="center">
              <tr>
                <th>Eil. Nr.</th>
                <th>Vardas Pavardė</th>
                <th v-for="(title, y) in titles" :key="y" class="text-center">
                  {{ title }}
                </th>
              </tr>
             
            </thead>
            <tbody>
              <tr v-for="(item, i) in getMokiniaiList" :key="i">
                <td>{{ 1 + i }}</td>
                <td>{{ item?.name }} {{ item?.surname }}</td>
                <td v-for="(itemas, y) in mokinioPazymiai1(item.id)" :key="y">
                  {{ itemas }}
                </td>
              </tr>
               <tr>
                <th></th>
                <th>Klasės Vidurkis</th>
                <th v-for="(title, x) in titles" :key="x">
                  {{ getVidurkis(title) }}
                </th>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </base-material-card>
    </div>
  </v-container>
</template>

<script>
import baseMaterialCard from "@/components/base/MaterialCard.vue";
import loginSite from "@/components/LoginSite.vue";

export default {
  name: "manoklase-view",
  components: {
    baseMaterialCard,
    loginSite,
  },
  data() {
    return {
      titles: [
        "5 - klasė-I",
        "mano-5 - klasė-I",
        "5 - klasė-II",
        "mano-5 - klasė-II",
        "6 - klasė-I",
        "mano-6 - klasė-I",
        "6 - klasė-II",
        "mano-6 - klasė-II",
        "7 - klasė-I",
        "mano-7 - klasė-I",
        "7 - klasė-II",
        "mano-7 - klasė-II",
        "8 - klasė-I",
        "mano-8 - klasė-I",
        "8 - klasė-II",
        "mano-8 - klasė-II",
      ],
      select: this.$store.state.mokytojoKlase[0],
      klase: this.$store.state.mokytojoKlase,
      mokiniai: this.$store.state.mokiniai,
      valid: true,

      errors: [],
    };
  },
  // https://vuex.vuejs.org/guide/actions.html#composing-actions
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },

    getMokiniaiList() {
      return this.$store.getters.mokiniaiList(this.select.id);
    },
    
  },
  methods: {
    getVidurkis(paz_uz) {
      var list = this.$store.getters.mokiniaiList(this.select.id)
      var listas = []
     list.forEach(element => {
      listas.push(element.id)
     });
      return this.$store.getters.getKlasesVidurkis(paz_uz, listas);

    },
    mokinioPazymiai1(id) {
      var listas = [];
      var pazymiai = this.$store.getters.mokinioPazymiai(id);

      listas = this.titles.map(myFunction);

      function myFunction(value) {
        for (var pazimys of pazymiai) {
          if (pazimys["pazimys_uz"] == value) {
            return pazimys["pazimys"];
          }
          // return "-";
        }
      }
      console.log("+++++------------>", listas);
      return listas;
    },
  },
};
</script>
