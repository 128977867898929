var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"klase","fluid":""}},[_c('loginSite',{directives:[{name:"show",rawName:"v-show",value:(!this.$store.state.isAuthenticated),expression:"!this.$store.state.isAuthenticated"}]}),(
      this.$store.state.isAuthenticated &
      (this.$store.state.userRole === 'STUDENT')
    )?_c('div',{staticClass:"mx-auto",attrs:{"fluid":""}},[_c('base-material-card',{attrs:{"color":_vm.spalva},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"text-h6 text-md-h5 text-lg-h4 text-xl-h3"},[_vm._v(" Mano kompetencijos ")])]},proxy:true}],null,false,2265125682)},[_c('v-row',[(_vm.pirmasPusmetis())?_c('span',[(
              _vm.useratsakymailist?.length == _vm.klausimai?.length ? false : true
            )?_c('v-col',_vm._l((_vm.klausimai),function(klausimas,i){return _c('v-radio-group',{directives:[{name:"show",rawName:"v-show",value:(!_vm.useratsakymailist?.includes(klausimas?.id)),expression:"!useratsakymailist?.includes(klausimas?.id)"}],key:i,attrs:{"row":_vm.notMobile},on:{"change":function($event){return _vm.addAtsakymas(klausimas?.id, _vm.radioGr[i])}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(_vm._s(klausimas?.name))])]},proxy:true}],null,true),model:{value:(_vm.radioGr[i]),callback:function ($$v) {_vm.$set(_vm.radioGr, i, $$v)},expression:"radioGr[i]"}},[_vm._l((_vm.atsakymai),function(item,i){return _c('v-radio',{key:i,staticClass:"d-block",attrs:{"value":item.id},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('img',{attrs:{"contain":"","max-height":_vm.maxHeight,"src":'/static/dist/img/' + item?.id + '.png',"alt":"smile"}})])]},proxy:true}],null,true)})}),_c('p',[_vm._v(_vm._s(_vm.radioGr[i]))])],2)}),1):_vm._e()],1):_vm._e(),(!_vm.pirmasPusmetis())?_c('span',[(
              _vm.useratsakymailist?.length == _vm.klausimai?.length ? false : true
            )?_c('v-col',_vm._l((_vm.klausimai),function(klausimas,i){return _c('v-radio-group',{directives:[{name:"show",rawName:"v-show",value:(!_vm.useratsakymailist?.includes(klausimas?.id)),expression:"!useratsakymailist?.includes(klausimas?.id)"}],key:i,attrs:{"row":_vm.notMobile},on:{"change":function($event){return _vm.addAtsakymas(klausimas?.id, _vm.radioGr[i])}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(_vm._s(klausimas?.name))])]},proxy:true}],null,true),model:{value:(_vm.radioGr[i]),callback:function ($$v) {_vm.$set(_vm.radioGr, i, $$v)},expression:"radioGr[i]"}},[_c('v-row',{staticClass:"pt-2 pl-2 ma-0"},_vm._l((_vm.atsakymai),function(item,i){return _c('v-radio',{key:i,attrs:{"value":item?.id},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('img',{attrs:{"contain":"","max-height":_vm.maxHeight,"src":'/static/dist/img/' + item?.id + '.png',"alt":"smile"}})])]},proxy:true}],null,true)})}),1)],1)}),1):_vm._e()],1):_vm._e(),_c('v-col',[_c('v-simple-table',{attrs:{"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Aš")]),_c('th',{staticClass:"text-left"},[_vm._v("Pirmas Pusmetis")]),_c('th',{staticClass:"text-left"},[_vm._v("Antras Pusmetis")])])]),_c('tbody',_vm._l((_vm.useratsakymaiVisi),function(itemas,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(itemas.klausimas_detail?.name))]),_c('td',[_c('img',{attrs:{"contain":"","max-height":_vm.maxHeight,"src":'/static/dist/img/' +
                        _vm.atsakymai[itemas.atsakymas - 1].id +
                        '.png',"alt":"smile"}})]),_c('td',[(itemas.atsakymasII)?_c('img',{attrs:{"contain":"","max-height":_vm.maxHeight,"src":'/static/dist/img/' +
                        _vm.atsakymai[itemas.atsakymasII - 1]?.id +
                        '.png',"alt":"smile"}}):_vm._e()])])}),0)]},proxy:true}],null,false,1015657263)})],1)],1)],1)],1):_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('p',[_vm._v("Jūs nesate mokinys")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }