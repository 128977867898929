<template>
  <v-container id="pasiekimai" fluid>
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>
    <div
      class="mx-auto"
      v-if="
        this.$store.state.isAuthenticated &
        (this.$store.state.userRole === 'STUDENT')
      "
      fluid
    >
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">
            Čia mano pasiekimai
          </div>
        </template>
        <v-row>
          <!-- Įvedimas -->
          <v-dialog v-model="dialogAdd" max-width="700">
            <v-card>
              <v-card-title class="text-h5"
                >Pridėti naują pasiekimą
              </v-card-title>

              <v-card-text>
                <!-- Forma -->
                <v-form>
                  <v-container class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Pavadinimas"
                          class="purple-input"
                          v-model="title"
                          required
                          :rules="[rules.required]"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Turinys"
                          class="purple-input"
                          v-model="content"
                          required
                          :rules="[rules.required]"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="6">
                        <v-color-picker
                          hide-inputs
                          v-model="color"
                          elevation="15"
                        ></v-color-picker>
                      </v-col>
                      <v-col cols="6">
                        <v-img
                          contain
                          :aspect-ratio="16 / 9"
                          max-height="200"
                          max-width="500"
                          :src="img"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  v-if="errors.length"
                >
                  <p v-for="error in errors" :key="error.id">
                    {{ error }}
                  </p>
                </v-alert>
                <!-- Forma end-->
              </v-card-text>

              <v-card-actions>
                <v-btn :color="spalva" @click="toggleShow">
                  Pridėti nuotrauką
                </v-btn>
                <v-btn :color="spalva" @click="addNew">
                  Saugoti pakeitimus
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn :color="spalva" @click="dialogAdd = false">
                  Uždaryti
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Įvedimas end-->
        </v-row>
        <v-row>
          <my-upload
            langType="en"
            field="img"
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            v-model="showImput"
            :width="200"
            :height="200"
            :params="params"
            :headers="headers"
            img-format="png"
          ></my-upload>
          <v-col cols="12" class="text-right d-sm-flex">
            <v-btn :color="spalva" class="mr-0" @click="dialogAdd = true">
              Pridėti naują pasiekimą
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-card class="mx-auto">
            <v-container class="pt-10">
              <v-row dense>
                <v-col
                  class="mt-10 mr-11"
                  v-for="(item, i) in pasiekimai"
                  :key="i"
                >
                  <v-card
                    elevation="3"
                    shaped
                    :color="item.color"
                    dark
                    min-width="300"
                  >
                    <div class="d-flex flex-no-wrap justify-space-between">
                      <div>
                        <v-card-title class="text-h6 text-wrap mr-10">{{
                          item.title
                        }}</v-card-title>
                        <v-card-subtitle> </v-card-subtitle>
                        <v-card-text class="text-wrap"
                          ><div v-html="item.content"></div
                        ></v-card-text>
                        <v-card-actions>
                          <!-- Redaguoti -->
                          <v-dialog v-model="item.dialogEdit">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                elevation="2"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon> mdi-shield-edit-outline </v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title class="text-h5"
                                >Redaguoti pasiekimą
                              </v-card-title>

                              <v-card-text>
                                <!-- Forma -->
                                <v-form>
                                  <v-container class="py-0">
                                    <v-row>
                                      <v-col cols="12">
                                        <v-text-field
                                          label="Pavadinimas"
                                          v-model="item.title"
                                          required
                                          :rules="[rules.required]"
                                        />
                                      </v-col>

                                      <v-col cols="12">
                                        <tiptap-vuetify
                                          v-model="item.content"
                                          :extensions="extensions"
                                        ></tiptap-vuetify>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-color-picker
                                          hide-inputs
                                          v-model="item.color"
                                          elevation="15"
                                        ></v-color-picker>
                                      </v-col>
                                      <v-col cols="6">
                                        <v-img
                                          contain
                                          :aspect-ratio="16 / 9"
                                          max-height="200"
                                          max-width="500"
                                          :src="!img ? item.img : img"
                                        ></v-img>
                                        <!-- ++++++++++++++++++++++++++++++++++++++++++++++ -->
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-form>
                                <v-alert
                                  border="bottom"
                                  colored-border
                                  type="warning"
                                  elevation="2"
                                  v-if="errors.length"
                                >
                                  <p v-for="error in errors" :key="error.id">
                                    {{ error }}
                                  </p>
                                </v-alert>
                                <!-- Forma end-->
                              </v-card-text>

                              <v-card-actions>
                                <v-btn :color="spalva" @click="toggleShow">
                                  Pridėti nuotrauką
                                </v-btn>
                                <v-btn
                                  :color="spalva"
                                  @click="
                                    update(
                                      i,
                                      item.id,
                                      item.title,
                                      item.content,
                                      item.color
                                    )
                                  "
                                >
                                  Saugoti pakeitimus
                                </v-btn>
                                <v-spacer></v-spacer>

                                <v-btn
                                  :color="spalva"
                                  @click="item.dialogEdit = false"
                                >
                                  Uždaryti
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                          <!-- Redaguoti end-->

                          <v-dialog v-model="item.removeDialog" width="500">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                elevation="2"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon> mdi-trash-can-outline </v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title class="text-h5 grey lighten-2">
                                Ištrinti pasiekimą
                              </v-card-title>

                              <v-card-text>
                                Ar tikrai norite ištrinti "{{ item.title }}"
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  :color="spalva"
                                  text
                                  @click="item.removeDialog = false"
                                >
                                  Atsisakyti
                                </v-btn>
                                <v-btn
                                  :color="spalva"
                                  text
                                  @click.stop.prevent="remove(item?.id)"
                                >
                                  Trinti
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-card-actions>
                      </div>
                      <v-dialog v-model="item.dialog" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn elevation="2" icon v-bind="attrs" v-on="on">
                            <v-avatar class="ma-3" size="125">
                              <v-img :src="item.img" contain></v-img>
                            </v-avatar>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5"> </v-card-title>

                          <v-card-text>
                            <v-img :src="item.img"></v-img>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                              color="green darken-1"
                              text
                              @click="item.dialog = false"
                            >
                              Uždaryti
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-row>
      </base-material-card>
    </div>
  </v-container>
</template>

<script>
import loginSite from "@/components/LoginSite.vue";
import baseMaterialCard from "@/components/base/MaterialCard.vue";
import axios from "axios";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  components: {
    loginSite,
    baseMaterialCard,
    "my-upload": myUpload,
    TiptapVuetify,
  },
  name: "Pasiekimai-view",
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      // starting editor's content
      content: "",
      rules: {
        required: (value) => !!value || "Privalomas",
        min: (value) => value.length >= 8 || "Min 8 simboliai",
      },
      params: {
        // token: this.token(),
        name: "avatar",
      },
      headers: {
        Authorization: "Token " + this.$store.state.token,
      },
      showImput: false,
      dialog: false,
      dialogAdd: false,
      dialogEdit: false,
      removeDialog: false,
      img: "",
      src: "",
      title: "",
      // content: "",
      color: "",
      errors: [],
      error: "",
    };
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
    pasiekimai() {
      return this.$store.state.pasiekimai;
    },
  },
  methods: {
    async remove(id) {
      // console.log("remove --->Starting", id);
      this.$store.commit("setIsLoading", true);
      this.errors = [];

      await axios
        .delete("/api/pasiekimai/" + id)
        .then((response) => {
          console.log("deleted Pasiekimas", response.data);
          this.$store.dispatch("getPasiekimai");
          // ištrinti galima ir be kreipimosi i beckenda
          this.removeDialog = false;

          // this.showInfo = true;
          //   setTimeout(() => (this.showInfo = false), 10000);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
    toggleShow() {
      this.showImput = !this.showImput;
    },
    update(i, id, title, content, color) {
      this.$store.commit("setIsLoading", true);
      const data = {
        title: title,
        content: content,
        class_value: this.$store.state.mokinioKlase,
        color: color,
      };
      if (this.img.startsWith("data:image/png;base64")) {
        data.img = this.img;
      }
      axios
        .patch("/api/pasiekimai/" + id, data)
        .then((response) => {
          this.$store.state.pasiekimai[i] = response.data;
          this.dialogEdit = false;
          this.img = "";
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
    addNew() {
      this.$store.commit("setIsLoading", true);
      const data = {
        img: this.img,
        title: this.title,
        content: this.content,
        class_value: this.$store.state.mokinioKlase,
        color: this.color,
      };
      axios
        .post("/api/pasiekimai/", data)
        .then((response) => {
          // location.reload();
          // console.log("Responsas-->", response.data.img);
          // console.log("Responsas-->", response.data.img.toString());
          // this.imgUrl = response.data.img.toString();
          this.$store.state.pasiekimai.push(response.data);
          this.dialogAdd = false;
          this.img = "";
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl) {
      this.img = imgDataUrl;
      this.src = imgDataUrl;
      console.log("-------- crop success --------", this.img);
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
  },
};
</script>
<style>
.v-card__title {
  word-break: normal;
}
</style>
