import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import cookie from "vue-cookies";

Vue.use(Vuex);
async function checkResponse(urlas) {
  await axios
    .get(urlas)
    .then((response) => {
      if (response.status == 200) {
        console.log("checkResponse response.status -->", response.status);
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      // console.log("checkResponse ERROR -->", error);
    });
}
export default new Vuex.Store({
  state: {
    isLoading: false,
    isAuthenticated: false,
    spalva: "blue-grey",
    token: "",
    userId: "",
    userName: "",
    userSurname: "",
    userEmail: "",
    userRole: "",
    userProfileId: "",
    userImage: "/media/uploads/avatar.png",
    mokinioKlase: "",
    mokinioKlasePav: "",
    mokytojoKlase: [],
    entrys: [],
    radioGr: [1, 2, 3, 4, 5, 6],
    klausimai: [],
    klausimaiTevam: [],
    atsakymaiByKlaseI: [],
    atsakymaiByKlaseII: [],
    useratsakymai: [],
    useratsakymaiI: [],
    useratsakymaiII: [],
    useratsakymailistI: [],
    useratsakymailistII: [],
    userpazymiai: [],
    mokiniai: [],
    klasiuMokiniai: [],
    pazymiai: [],
    pages: [],
    visosSeimos: [],
    visiPazymiai: [],
    pusmeciai: [],
    komentarai: [],
    pasiekimai: [],
    files: [],
    pirmasPusmetis: "",
    questionStatus: "",
    atsakymai: [
      { id: "1", text: "pirmas" },
      { id: "2", text: "antras" },
      { id: "3", text: "trecias" },
    ],
  },
  getters: {
    userRole(state) {
      if (state.userRole === "STUDENT") {
        return "MOKINYS";
      } else if (state.userRole === "TEATCHER") {
        return "MOKYTOJAS";
      } else if (state.userRole === "ADMIN") {
        return "ADMINAS";
      } else if (state.userRole === "PARENTS") {
        return "TĖVAI";
      } else {
        return state.userRole;
      }
    },
    kelintokas(state) {
      if (state.mokinioKlase === 5) {
        return "penktokas";
      } else if (state.mokinioKlase === 6) {
        return "šeštokas";
      } else if (state.mokinioKlase === 7) {
        return "septintokas";
      } else if (state.mokinioKlase === 8) {
        return "aštuntokas";
      }
    },
    mokinioKlase(state) {
      if (state.mokinioKlase === 5) {
        return "Penkta klasė";
      } else if (state.mokinioKlase === 6) {
        return "Šešta klasė";
      } else if (state.mokinioKlase === 7) {
        return "Septinta klasė";
      } else if (state.mokinioKlase === 8) {
        return "Aštunta klasė";
      }
    },
    getFiles(state) {
      return state.files;
    },
    getKlasesVidurkis:(state) => (paz_uz, mokiniuIdList) => {
      var sum = 0;
      let count = 0;
      let pazList = state.visiPazymiai.filter((el) => el.pazimys_uz === paz_uz)
      pazList.forEach((element) => {
        mokiniuIdList.forEach((el) => {
          if (element.mokinys == el) {
            sum += parseFloat(element.pazimys);
            count++;
          }
        })
      });
      return isNaN(sum / count)
          ? "-"
          : (sum / count).toFixed(2);
    },
   
    userImage(state) {
      return state.userImage;
    },
    mokytojoKlase(state) {
      return state.mokytojoKlase[0].name;
    },
    mokytojoKlasesId(state) {
      return state.mokytojoKlase[0].id;
    },
    mokiniaiList: (state) => (id) => {
      let mokList = state.mokiniai.filter((el) => el.klase === id);
      if (mokList.length !== 0) {
        return mokList[0].mokiniai;
      } else {
        return ['Nera'];
      }
    },
    getKlaseAtsakymaiByKlaseI: (state, getters) => (id) => {
      var mokList = [];
      for (let mok of getters.mokiniaiList(id)) {
        var rezult = {};
        var count5 = 0;
        var count6 = 0;
        var count7 = 0;
        var count8 = 0;
        var i5 = 0;
        var i6 = 0;
        var i7 = 0;
        var i8 = 0;
        for (let el of state.atsakymaiByKlaseI) {
          if (el.mokinys === mok?.id) {
            if (el.class_value == 5) {
              count5 = count5 + el.atsakymas_detail.value;
              i5++;
            } else if (el.class_value == 6) {
              count6 = count6 + el.atsakymas_detail.value;
              i6++;
            } else if (el.class_value == 7) {
              count7 = count7 + el.atsakymas_detail.value;
              i7++;
            } else if (el.class_value == 8) {
              count8 = count8 + el.atsakymas_detail.value;
              i8++;
            }
          }
        }
        rezult.atsakymas5I = isNaN(count5 / i5)
          ? "-"
          : (count5 / i5).toFixed(2);
        rezult.atsakymas6I = isNaN(count6 / i6)
          ? "-"
          : (count6 / i6).toFixed(2);
        rezult.atsakymas7I = isNaN(count7 / i7)
          ? "-"
          : (count7 / i7).toFixed(2);
        rezult.atsakymas8I = isNaN(count8 / i8)
          ? "-"
          : (count8 / i8).toFixed(2);
        rezult.mokinys = mok.name + " " + mok.surname;
        mokList.push(rezult);
      }
      return mokList;
    },
    getKlaseAtsakymaiByKlaseII: (state, getters) => (id) => {
      var mokList = [];
      for (let mok of getters.mokiniaiList(id)) {
        var rezult = {};
        var count5 = 0;
        var count6 = 0;
        var count7 = 0;
        var count8 = 0;
        var i5 = 0;
        var i6 = 0;
        var i7 = 0;
        var i8 = 0;
        for (let el of state.atsakymaiByKlaseII) {
          if (el.mokinys === mok?.id) {
            if (el.class_value == 5) {
              count5 = count5 + el.atsakymas_detail.value;
              i5++;
            } else if (el.class_value == 6) {
              count6 = count6 + el.atsakymas_detail.value;
              i6++;
            } else if (el.class_value == 7) {
              count7 = count7 + el.atsakymas_detail.value;
              i7++;
            } else if (el.class_value == 8) {
              count8 = count8 + el.atsakymas_detail.value;
              i8++;
            }
          }
        }
        rezult.atsakymas5II = isNaN(count5 / i5)
          ? "-"
          : (count5 / i5).toFixed(2);
        rezult.atsakymas6II = isNaN(count6 / i6)
          ? "-"
          : (count6 / i6).toFixed(2);
        rezult.atsakymas7II = isNaN(count7 / i7)
          ? "-"
          : (count7 / i7).toFixed(2);
        rezult.atsakymas8II = isNaN(count8 / i8)
          ? "-"
          : (count8 / i8).toFixed(2);
        rezult.mokinys = mok.name + " " + mok.surname;
        mokList.push(rezult);
      }
      return mokList;
    },

    mokinioPazymiai: (state) => (id) => {
      return state.visiPazymiai.filter((el) => el.mokinys === id);
    },
    mokinioTevas: (state) => (id) => {
      const tev = state.visosSeimos.filter((el) => el.child.id === id);
      return tev;
    },
    mokTevas(state) {
      return function (id) {
        state.visosSeimos.filter((el) => el.child.id === id);
      };
    },
    komentarasMokiniui: (state) => {
      // const klaseValue = state.klasiuMokiniai.filter((el) =>
      //   el.mokiniai.includes(id)
      // );
      const klausimaiTevamPagalKlase = state.klausimaiTevam.filter(
        // (el) => el.value <= klaseValue[0]?.klase.value
        (el) => el.value == state.mokinioKlase
      );
      const copyList = [];
      klausimaiTevamPagalKlase.forEach((item) => copyList.push(item.id));
      const koment = state.komentarai.filter(
        (el) =>
          el.child === state.userId && copyList.includes(el.klausimas_tevam)
      );
      return koment;
    },
    getUserAtsakymai: (state) => (id) => {
      console.log(
        "getUserAtsakymai---getUserAtsakymai",
        state.useratsakymaiI,
        state.useratsakymaiII
      );
      const uatsakymai = state.useratsakymaiI.filter(
        (el) => el.class_value == id
      );
      const uatsakymaiII = state.useratsakymaiII.filter(
        (el) => el.class_value == id
      );

      uatsakymai.forEach((item) => {
        for (let itemas of uatsakymaiII) {
          if (item.klausimas === itemas.klausimas) {
            item.atsakymasII = itemas.atsakymas;
          }
        }
      });
      console.log("getUserAtsakymai---getUserAtsakymai", uatsakymai);
      return uatsakymai;
    },
    getUserAtsakymaiListByKlaseI: (state) => (id) => {
      const klausimaiByKlase = state.useratsakymaiI.filter(
        (el) => el.class_value == id
      );
      console.log("klausimaiByKlase+++++++++++", klausimaiByKlase);
      let klausimai = [];
      klausimaiByKlase.forEach((item) => {
        klausimai.push(item["klausimas"]);
      });
      return klausimai;
    },
    getUserAtsakymaiListByKlaseII: (state) => (id) => {
      const klausimaiByKlase = state.useratsakymaiII.filter(
        (el) => el.class_value == id
      );
      console.log("klausimaiByKlaseII II II II", klausimaiByKlase);
      let klausimai = [];
      klausimaiByKlase.forEach((item) => {
        klausimai.push(item["klausimas"]);
      });
      return klausimai;
    },
    getResults: (state, getters) => {
      let results = [];
      state.klausimai.forEach((el) =>
        results.push({
          klausimas: el,
          category: el.category,
          atsakymas5I: 0,
          atsakymas5II: 0,
          atsakymas6I: 0,
          atsakymas6II: 0,
          atsakymas7I: 0,
          atsakymas7II: 0,
          atsakymas8I: 0,
          atsakymas8II: 0,
          total5: function () {
            return ((this.atsakymas5I + this.atsakymas5II) / 2).toFixed(2);
          },
          total6: function () {
            return ((this.atsakymas6I + this.atsakymas6II) / 2).toFixed(2);
          },
          total7: function () {
            return ((this.atsakymas7I + this.atsakymas7II) / 2).toFixed(2);
          },
          total8: function () {
            return ((this.atsakymas8I + this.atsakymas8II) / 2).toFixed(2);
          },
          total: function () {
            return (
              parseFloat(
                this.total5() + this.total6() + this.total7() + this.total8()
              ) / 4
            ).toFixed(2);
          },
        })
      );

      for (let i of [5, 6, 7, 8]) {
        getters.getUserAtsakymaiByKlaseI(i).forEach((el) => {
          results.forEach((elem) => {
            if (el.klausimas_detail.id == elem.klausimas.id && i == 5) {
              elem.atsakymas5I = el.atsakymas_detail.value;
            } else if (el.klausimas_detail.id == elem.klausimas.id && i == 6) {
              elem.atsakymas6I = el.atsakymas_detail.value;
            } else if (el.klausimas_detail.id == elem.klausimas.id && i == 7) {
              elem.atsakymas7I = el.atsakymas_detail.value;
            } else if (el.klausimas_detail.id == elem.klausimas.id && i == 8) {
              elem.atsakymas8I = el.atsakymas_detail.value;
            }
          });
        });
        getters.getUserAtsakymaiByKlaseII(i).forEach((el) => {
          results.forEach((elem) => {
            if (el.klausimas_detail.id == elem.klausimas.id && i == 5) {
              elem.atsakymas5II = el.atsakymas_detail.value;
            } else if (el.klausimas_detail.id == elem.klausimas.id && i == 6) {
              elem.atsakymas6II = el.atsakymas_detail.value;
            } else if (el.klausimas_detail.id == elem.klausimas.id && i == 7) {
              elem.atsakymas7II = el.atsakymas_detail.value;
            } else if (el.klausimas_detail.id == elem.klausimas.id && i == 8) {
              elem.atsakymas8II = el.atsakymas_detail.value;
            }
          });
        });
      }
      return results;
    },
    getUserAtsakymaiByKlaseI: (state) => (id) => {
      return state.useratsakymaiI.filter((el) => el.class_value == id);
    },
    getUserAtsakymaiByKlaseII: (state) => (id) => {
      return state.useratsakymaiII.filter((el) => el.class_value == id);
    },
    getKlausimaiTevam: (state) => (klase) => {
      const koment = state.klausimaiTevam.filter((el) => el.value == klase);
      console.table("klausimaiTevamklausimaiTevam", state.klausimaiTevam);
      return koment;
    },
    getPage: (state) => (role) => {
      const page = state.pages.filter((el) => el.role == role);
      console.log("getPage getPage", page);
      return page;
    },
    getPageByRole: (state) => {
      return state.pages.filter((el) => el.role == state.userRole);
    },
    TevoMokiniai: (state) => () => {
      console.table("state.visosSeimosstate.visosSeimos", state.visosSeimos);

      const children = state.visosSeimos.filter(
        (el) => el.parent.id === state.userId
      );
      const copyItems = [];
      children.forEach((item) => copyItems.push(item.child));
      return copyItems;
    },
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
      } else {
        state.token = "";
        state.entrys = [];
        state.isAuthenticated = false;
      }
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setSpalva(state) {
      if (cookie.isKey("color")) {
        state.spalva = cookie.get("color");
      }
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      localStorage.removeItem("token");
      state.token = "";
      state.isAuthenticated = false;
    },
    setUserId(state, context) {
      state.userId = context;
    },
    setUserEmail(state, context) {
      state.userEmail = context;
    },
    setUserRole(state, context) {
      state.userRole = context;
    },
    setUserImage(state, context) {
      state.userImage = context;
    },
    setUserProfileId(state, context) {
      state.userProfileId = context;
    },
    setEntrys(state, context) {
      state.entrys = context;
    },
    setUserName(state, context) {
      state.userName = context;
    },
    setUserSurname(state, context) {
      state.userSurname = context;
    },
    setKlausimai(state, context) {
      state.klausimai = context;
    },
    setKlausimaiTevam(state, context) {
      state.klausimaiTevam = context;
    },
    setMokinioKlase(state, context) {
      state.mokinioKlase = context;
    },
    setMokinioKlasePav(state, context) {
      state.mokinioKlasePav = context;
    },
    setMokytojoKlase(state, context) {
      state.mokytojoKlase = context;
    },
    setAtsakymaiByKlaseI(state, context) {
      state.atsakymaiByKlaseI = context;
    },
    setAtsakymaiByKlaseII(state, context) {
      state.atsakymaiByKlaseII = context;
    },
    setAtsakymai(state, context) {
      state.useratsakymai = context;
    },
    setAtsakymaiI(state, context) {
      state.useratsakymaiI = context;
    },
    setAtsakymaiII(state, context) {
      state.useratsakymaiII = context;
    },
    updateAtsakymaiI(state, context) {
      state.useratsakymaiI.push(context);
    },
    updateAtsakymaiII(state, context) {
      state.useratsakymaiII.push(context);
    },
    updatePazymiai(state, context) {
      state.pazymiai.push(context);
    },
    updatePalinkejimai(state, context) {
      state.komentarai.push(context);
    },
    setListAtsakymaiI(state, context) {
      state.useratsakymailistI = context;
    },
    setListAtsakymaiII(state, context) {
      state.useratsakymailistII = context;
    },
    setManoPazymiai(state, context) {
      state.pazymiai = context;
    },
    setPazymiai(state, context) {
      state.visiPazymiai = context;
    },
    setSeimos(state, context) {
      state.visosSeimos = context;
    },
    setUserPazymiai(state, context) {
      state.userpazymiai = context;
    },
    setMokiniai(state, context) {
      state.mokiniai = context;
    },
    setKlasiuMokiniai(state, context) {
      state.klasiuMokiniai = context;
    },
    setPusmeciai(state, context) {
      state.pusmeciai = context;
    },
    setPusmetis(state, context) {
      state.pirmasPusmetis = context;
    },
    setPasiekimai(state, context) {
      state.pasiekimai = context;
    },
    setQuestionStatus(state, context) {
      state.questionStatus = context;
    },
    setKomentarai(state, context) {
      state.komentarai = context;
    },
    setPage(state, context) {
      state.pages = context;
    },
    setFiles(state, context) {
      state.files = context;
    },
    removeUserData(state) {
      state.userId = "";
      state.userEmail = "";
      state.userName = "";
      state.userSurname = "";
      state.userRole = "";
      state.userProfileId = "";
      state.userImage = "";
      state.mokinioKlase = "";
      state.mokinioKlasePav = "";
      state.atsakymaiByKlase = [];
      state.useratsakymaiI = [];
      state.useratsakymailistI = [];
      state.useratsakymaiII = [];
      state.useratsakymailistII = [];
      state.pazymiai = [];
      state.pusmeciai = [];
      state.klausimai = [];
      state.pages = [];
      state.userpazymiai = [];
      state.pirmasPusmetis = "";
      
    },
  },
  // https://vuex.vuejs.org/guide/actions.html#composing-actions
  // actions: {
  //   async actionA ({ commit }) {
  //     commit('gotData', await getData())
  //   },
  //   async actionB ({ dispatch, commit }) {
  //     await dispatch('actionA') // wait for `actionA` to finish
  //     commit('gotOtherData', await getOtherData())
  //   }
  // }
  actions: {
    async getUserInfo(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/auth/users/me/")
        .then((response) => {
          context.commit("setUserId", response.data.id);
          context.commit("setUserEmail", response.data.email);
          context.commit("setUserRole", response.data.role);
          context.commit("setUserName", response.data.name);
          context.commit("setUserSurname", response.data.surname);
          context.dispatch("getPusmetis");
          context.dispatch("getPages");
          context.dispatch("getFiles");

          let urlas = "";
          if (response.data.role == "TEACHER") {
            urlas = "/api/teatcherprofile/" + this.state.userId + "/profile";
            context.dispatch("getMokytojoKlase");
            context.dispatch("getMokiniai");
            context.dispatch("getPazymiai");
            context.dispatch("getSeima");
          } else if (response.data.role == "STUDENT") {
            urlas = "/api/studentprofile/" + this.state.userId + "/profile";
            context.dispatch("getKlausimai");
            context.dispatch("getAtsakymai");
            context.dispatch("getManoPazymiai");
            context.dispatch("getMokinioKlase");
            context.dispatch("getKomentarai");
            context.dispatch("getKlausimaiTevam");
            context.dispatch("getKlasiuMokiniai");
            context.dispatch("getPasiekimai");
          } else {
            urlas = "/api/parentsprofile/" + this.state.userId + "/profile";
            context.dispatch("getSeima");
            context.dispatch("getKomentarai");
            context.dispatch("getKlausimaiTevam");
            context.dispatch("getKlasiuMokiniai");
          }
          context.dispatch("getUserProfile", urlas);
        })
        .catch((error) => {
          console.log(error);
        });
      context.commit("setIsLoading", false);
    },
    async changePusmetis(context, is_first) {
      const data = {
        is_first: is_first,
      };
      context.commit("setIsLoading", true);
      await axios
        .patch("/api/pusmetis/1", data)
        .then((response) => {
          context.commit("setPusmetis", response.data.is_first);
        })
        .catch((error) => {
          console.log(error);
        });
      context.commit("setIsLoading", false);
    },
    async getPusmetis(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/pusmetis/1")
        .then((response) => {
          context.commit("setPusmetis", response.data.is_first);
        })
        .catch((error) => {
          console.log(error);
        });
      context.commit("setIsLoading", false);
    },
    async getMokinioKlase(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/mokinio-klase/")
        .then((response) => {
          context.commit("setMokinioKlase", response.data[0].value);
          context.commit("setMokinioKlasePav", response.data[0].name);

          console.log("MokinioKlase", response.data);
          console.log("MokinioKlase", this.state.mokinioKlase);
        })
        .catch((error) => {
          console.log("getMokinioKlase ERROR -->", error);
        });

      context.commit("setIsLoading", false);
    },
    async getKlausimai(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/klausimai/")
        .then((response) => {
          context.commit("setKlausimai", response.data);
          console.log("/apiklausimai/response.data------>", response.data);
          console.log("Klausimai", this.state.klausimai);
        })
        .catch((error) => {
          console.log(error);
        });

      context.commit("setIsLoading", false);
    },
    async getKlausimaiTevam(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/klausimai_tevam/")
        .then((response) => {
          context.commit("setKlausimaiTevam", response.data);
          console.log("/klausimai_tevam/response.data------>", response.data);
          console.log("Klausimai klausimai_tevam", this.state.klausimaiTevam);
        })
        .catch((error) => {
          console.log(error);
        });

      context.commit("setIsLoading", false);
    },
    async getAtsakymai(context) {
      /* Pateikia atsakymus pagal prisijungusio mokinio ID
      Surušiuoja pagal pusmetį į setAtsakymaiI, setAtsakymaiII
      ir pagal pusmetį padaro atsakymų Listus setAtsakymaiIListI, setAtsakymaiIListII
      */
      context.commit("setIsLoading", true);
      await axios
        .get("/api/atsakytaapklausa/")
        .then((response) => {
          context.commit("setAtsakymai", response.data);
          let klausimaiI = [];
          let klausimaiII = [];
          response.data.forEach((value) => {
            if (value.is_first) {
              klausimaiI.push(value["klausimas"]);
            } else {
              klausimaiII.push(value["klausimas"]);
            }
          });
          context.commit("setListAtsakymaiI", klausimaiI);
          context.commit(
            "setAtsakymaiI",
            response.data.filter((el) => el.is_first)
          );
          context.commit("setListAtsakymaiII", klausimaiII);
          context.commit(
            "setAtsakymaiII",
            response.data.filter((el) => !el.is_first)
          );
        })
        .catch((error) => {
          console.log(error);
        });
      context.commit("setIsLoading", false);
    },
    async getAtsakymaiByKlase(context, kl) {
      /* Pateikia atsakymus pagal pateiktą klasių ID Listą ir
      Surušiuoja pagal pusmetį į setAtsakymaiByKlaseI, setAtsakymaiByKlaseII
      */
      context.commit("setIsLoading", true);
      await axios
        .post("/api/klases/apklausa/", kl)
        .then((response) => {
          context.commit(
            "setAtsakymaiByKlaseI",
            response.data.filter((el) => el.is_first)
          );
          context.commit(
            "setAtsakymaiByKlaseII",
            response.data.filter((el) => !el.is_first)
          );
        })
        .catch((error) => {
          console.log(error);
        });
      context.commit("setIsLoading", false);
    },
    async addAnswer(context, ev) {
      context.commit("setIsLoading", true);
      await axios
        .post("/api/atsakytaapklausa/", ev)
        .then((response) => {
          console.log("REsponsas--->", response.data);
          if (response.data.is_first) {
            context.commit("updateAtsakymaiI", response.data);
            console.log("Update User Atsakymai-->", this.state.useratsakymai);
          } else {
            context.commit("updateAtsakymaiII", response.data);
            console.log("Update User AtsakymaiII-->", this.state.useratsakymai);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      context.commit("setIsLoading", false);
    },
    async getPazymiai(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/pazymiai/")
        .then((response) => {
          console.log("/visi-pazymiai/------>", response.data);
          context.commit("setPazymiai", response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      context.commit("setIsLoading", false);
    },
    async getPasiekimai(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/pasiekimai/")
        .then((response) => {
          console.log("/OOOOOOO----pasiekimai------>", response.data);
          context.commit("setPasiekimai", response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      context.commit("setIsLoading", false);
    },
    async getSeima(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/seima/")
        .then((response) => {
          console.log("/visos-seimos/------>", response.data);
          context.commit("setSeimos", response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      context.commit("setIsLoading", false);
    },
    async getManoPazymiai(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/mano-pazymiai/")
        .then((response) => {
          console.log("/apipazymiai/------>", response.data);
          context.commit("setUserPazymiai", response.data);
          let paz = [];
          let pus = [];
          response.data.forEach((value) => {
            if (value["pazimys_uz"].substr(0, 4) == "mano") {
              paz.push(value["pazimys"]);
            } else {
              pus.push(value["pazimys"]);
            }
          });
          context.commit("setPusmeciai", pus);
          console.log("User Pusmeciai-->", this.state.pusmeciai);
          context.commit("setManoPazymiai", paz);
          console.log("User Pazymiai-->", this.state.pazymiai);
        })
        .catch((error) => {
          console.log(error);
        });

      context.commit("setIsLoading", false);
    },
    async addPazimys(context, paz) {
      context.commit("setIsLoading", true);
      console.log("REsponsas paz--->", paz);

      await axios
        .post("/api/mano-pazymiai/", paz)
        .then((response) => {
          console.log("REsponsas Add pazimys--->", response.data);
          context.commit("updatePazymiai", response.data["pazimys"]);
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
          console.log(error.toJSON());
        });
      context.commit("setIsLoading", false);
    },
    async getKlasiuMokiniai(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/visi-klasiu-mokiniai/")
        .then((response) => {
          context.commit("setKlasiuMokiniai", response.data);
          console.log(
            "<<<<<<<<<<<<<<<<<<<visi-klasiu-mokiniai>>>>>>>>>>>>>>>>>>",
            response.data
          );
        })
        .catch((error) => {
          console.log("setMokiniai ERROR -->", error);
        });
      context.commit("setIsLoading", false);
    },
    async getMokiniai(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/klases/mokiniai/")
        .then((response) => {
          context.commit("setMokiniai", response.data);
          console.log("setMokiniai", response.data);
        })
        .catch((error) => {
          console.log("setMokiniai ERROR -->", error);
        });
      context.commit("setIsLoading", false);
    },
    async getUserProfile(context, urlas) {
      context.commit("setIsLoading", true);
      await axios
        .get(urlas)
        .then((response) => {
          let x = response.data[0].img;
          if (checkResponse(x)) {
            context.commit("setUserImage", response.data[0].img);
            console.log("setUserImage", this.state.userImage);
            console.log("setUserImage-checkResponse=", checkResponse(x));
          } else {
            console.log("+++++++++setUserImage Default++++++++++");
          }
          context.commit("setUserProfileId", response.data[0].id);
          // context.commit("setSpalva", response.data[0].color);
          console.log("setUserProfileId response.data", response.data[0].id);
        })
        .catch((error) => {
          console.log("getUserProfile ERROR -->", error);
        });
      context.commit("setIsLoading", false);
    },
    async getKomentarai(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/palinkejimai/")
        .then((response) => {
          context.commit("setKomentarai", response.data);
          console.log(
            "setKomentaraiWWWWWWWWWWWWWW----------->>>",
            response.data
          );
        })
        .catch((error) => {
          console.log("setKomentarai ERROR -->", error);
        });
      context.commit("setIsLoading", false);
    },
    async getPages(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/page/")
        .then((response) => {
          context.commit("setPage", response.data);
          console.log("<---------- setPage ----------->>>", response.data);
        })
        .catch((error) => {
          console.log("setPage ERROR -->", error);
        });
      context.commit("setIsLoading", false);
    },
    async getMokytojoKlase(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/mokytojo-klase/")
        .then((response) => {
          context.commit("setMokytojoKlase", response.data);
          console.log("setMokytojoKlase----------->>>", response.data);
          var list = [];
          for (var klase of response.data) {
            console.log("**********************", klase.id);
            list.push(klase.id);
          }
          const dict = {
            id: list,
          };
          context.dispatch("getAtsakymaiByKlase", dict);
        })
        .catch((error) => {
          console.log("setMokytojoKlase ERROR -->", error);
        });

      context.commit("setIsLoading", false);
    },
    async postQuestion(context, q) {
      context.commit("setIsLoading", true);
      await axios
        .post("/post-question/", q)
        .then((response) => {
          context.commit("setQuestionStatus", response.data);
          console.log("setQuestionStatus", response.data);
        })
        .catch((error) => {
          console.log("setQuestionStatus ERROR -->", error);
        });
      context.commit("setIsLoading", false);
    },
    async uploadFile(context, file) {
      context.commit("setIsLoading", true);
      // Initialize the form data
      let formData = new FormData();
      // Add the form data we need to submit
      formData.append("file_path", file);
      console.log("uploadFile formData --->", formData);
      await axios
        .post("/api/files/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("uploadFile", response.data);
        })
        .catch((error) => {
          console.log("uploadFile ERROR -->", error);
        });
      context.commit("setIsLoading", false);
    },
    async deleteFile(context, file) {
      context.commit("setIsLoading", true);
      await axios
        .delete("/api/files/", file)
        .then((response) => {
          console.log("deleteFile", response.data);
        })
        .catch((error) => {
          console.log("deleteFile ERROR -->", error);
        });
      context.commit("setIsLoading", false);
    },
    async getFiles(context) {
      context.commit("setIsLoading", true);
      await axios
        .get("/api/files/")
        .then((response) => {
          console.log("getFiles", response.data);
          context.commit("setFiles", response.data);
        })
        .catch((error) => {
          console.log("getFiles ERROR -->", error);
        });
      context.commit("setIsLoading", false);
    },
  },

  modules: {},
});
