<template>
  <v-container id="admin-view" fluid>
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>
    <div
      class="mx-auto"
      v-if="
        this.$store.state.isAuthenticated &
        (this.$store.state.userRole === 'ADMIN')
      "
      fluid
    >
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">
            Čia Aš ... ADMIN'as
          </div>
        </template>
        <v-row>
          <v-card-text class="mt-12">
            <h2>
              <strong v-html="'Esate prisijungę prie knygelės'"></strong>
              <br />{{ x }}
              <v-file-input
                accept="image/*"
                show-size
                counter
                multiple
                label="File input"
                @change="upload"
              ></v-file-input>
              <v-treeview
                dense
                v-model="tree"
                :open="initiallyOpen"
                :items="items"
                activatable
                item-text="name"
                item-key="name"
                open-on-click
              > 
              <template v-slot:prepend="{ item, open }">
                  <v-icon v-if=!item.is_file>
                    {{ open ? "mdi-folder-open-outline" : "mdi-folder-outline" }}
                  </v-icon>
                  <v-icon v-else>
                    {{ files[item.filetype] }}
                  </v-icon>
                </template>
              </v-treeview>
              <br />
            </h2>
            <!-- <file-browser
              :axios="ax"
              storage="local"
              :endpoints="{
                list: { url: '/files', method: 'get' },
                upload: { url: '/files', method: 'post' },
                mkdir: { url: '/files/mkdir?path={path}', method: 'post' },
                delete: { url: '/files/{path}', method: 'delete' },
              }"
            /> -->
          </v-card-text>
        </v-row>
      </base-material-card>
    </div>
  </v-container>
</template>

<script>
import loginSite from "@/components/LoginSite.vue";
import baseMaterialCard from "@/components/base/MaterialCard.vue";
// import FileBrowser from "vuetify-file-browser";
import axios from "axios";

export default {
  components: {
    loginSite,
    baseMaterialCard,
    // FileBrowser,
  },
  name: "Admin-view",
  data() {
    return {
      x: "",
      errors: [],
      initiallyOpen: ["public"],
      files: {
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-code-json",
        md: "mdi-language-markdown",
        pdf: "mdi-file-pdf",
        png: "mdi-file-image",
        jpg: "mdi-file-image",
        svg: "mdi-file-image",
        txt: "mdi-file-document-outline",
        xls: "mdi-file-excel",
      },
      tree: [],
      // items: [
      //   {
      //     file_id: 1,
      //     file_path:
      //       "http://localhost:8000/media/uploads/user_72/98485b63-29d.png",
      //     since_added: "2023-03-28T12:24:24.126424Z",
      //     size: 103251,
      //     path: "uploads/user_72/98485b63-29d.png",
      //     is_file: true,
      //     filetype: "png",
      //     children: [],
      //   },
      //   {
      //     file_id: 2,
      //     file_path:
      //       "http://localhost:8000/media/uploads/user_1/36c34318-f0c.png",
      //     since_added: "2023-04-04T05:06:23.251083Z",
      //     size: 14177,
      //     path: "uploads/user_1/36c34318-f0c.png",
      //     is_file: false,
      //     filetype: "png",
      //     children: [],
      //   },
      //   {
      //     file_id: 3,
      //     file_path: "http://localhost:8000/media/uploads/user_1/Asset_5.png",
      //     since_added: "2023-04-04T05:42:46.669488Z",
      //     size: 13218,
      //     path: "uploads/user_1/Asset_5.png",
      //     is_file: true,
      //     filetype: "png",
      //     children: [],
      //   },
      //   {
      //     file_id: 4,
      //     file_path: "http://localhost:8000/media/uploads/user_1/Asset_1.png",
      //     since_added: "2023-04-04T06:35:10.482635Z",
      //     size: 14613,
      //     path: "uploads/user_1/Asset_1.png",
      //     is_file: false,
      //     filetype: "png",
      //     children: [],
      //   },
      //   {
      //     file_id: 5,
      //     file_path:
      //       "http://localhost:8000/media/uploads/user_1/Asset_1_rl62fVs.png",
      //     since_added: "2023-04-04T06:44:18.519302Z",
      //     size: 14613,
      //     path: "uploads/user_1/Asset_1_rl62fVs.png",
      //     is_file: false,
      //     filetype: "png",
      //     children: [],
      //   },
      //   {
      //     file_id: 6,
      //     file_path: "http://localhost:8000/media/uploads/user_1/Asset_4.png",
      //     since_added: "2023-04-04T07:01:21.958983Z",
      //     size: 12090,
      //     path: "uploads/user_1/Asset_4.png",
      //     is_file: false,
      //     filetype: "png",
      //     children: [3],
      //   },
      //   {
      //     file_id: 7,
      //     file_path: "http://localhost:8000/media/uploads/user_1/Asset_3.png",
      //     since_added: "2023-04-04T07:04:02.488950Z",
      //     size: 12832,
      //     path: "uploads/user_1/Asset_3.png",
      //     is_file: false,
      //     filetype: "png",
      //     children: [],
      //   },
      //   {
      //     file_id: 8,
      //     file_path:
      //       "http://localhost:8000/media/uploads/user_1/Asset_1_fS2pmBo.png",
      //     since_added: "2023-04-04T08:15:10.110583Z",
      //     size: 14613,
      //     path: "uploads/user_1/Asset_1_fS2pmBo.png",
      //     is_file: false,
      //     filetype: "png",
      //     children: [],
      //   },
      //   {
      //     file_id: 9,
      //     file_path:
      //       "http://localhost:8000/media/uploads/user_1/Asset_3_74HQ4mN.png",
      //     since_added: "2023-04-04T08:19:09.924859Z",
      //     size: 12832,
      //     path: "uploads/user_1/Asset_3_74HQ4mN.png",
      //     is_file: false,
      //     filetype: "png",
      //     children: [],
      //   },
      //   {
      //     file_id: 10,
      //     file_path:
      //       "http://localhost:8000/media/uploads/user_1/Asset_4_AaFvxz9.png",
      //     since_added: "2023-04-04T08:21:46.188145Z",
      //     size: 12090,
      //     path: "uploads/user_1/Asset_4_AaFvxz9.png",
      //     is_file: false,
      //     filetype: "png",
      //     children: [],
      //   },
      // ],
    };
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
    items() {
      return this.$store.getters.getFiles;
    },
  },
  methods: {
    upload(files) {
      this.$store.dispatch("uploadFile", files[0]);
    },
    ax() {
      const instance = axios.create({
        baseURL: "http://localhost:8000/api/api ",
      });
      // Alter defaults after instance has been created
      instance.defaults.headers.common["Authorization"] =
        "Token " + this.$store.state.token;
    },
  },
};
</script>
