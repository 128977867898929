<template>
  <v-container id="apie-mane" fluid>
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>
    <div
      class="mx-auto"
      v-if="
        this.$store.state.isAuthenticated &
        ((this.$store.state.userRole === 'STUDENT') ^
          (this.$store.state.userRole === 'ADMIN'))
      "
      fluid
    >
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">
            Tėvai apie mane ...
          </div>
        </template>
        <v-row>
          <v-col cols="4">
            <v-img
              src="\img\apie_mane.png"
              alt="Apie mane"
              max-height="250"
              max-width="250"
            ></v-img>
          </v-col>
          <v-col cols="8" class="mt-2">
            <v-row v-for="(itemas, y) in klausimaiTevam" :key="y">
              <p class="text-left title">
                <v-icon> mdi-hand-heart-outline </v-icon>
                {{ itemas.question }}
              </p>
              <div v-for="(item, i) in komentarasMok" :key="i">
                <p
                  v-if="item['klausimas_tevam'] === itemas.id"
                  class="text-left title"
                >
                  {{ item.text }}
                </p>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </base-material-card>
    </div>
  </v-container>
</template>

<script>
import loginSite from "@/components/LoginSite.vue";
import baseMaterialCard from "@/components/base/MaterialCard.vue";

export default {
  components: {
    loginSite,
    baseMaterialCard,
  },
  name: "Home-view",
  data() {
    return {
      child: "",
      userId: this.$store.state.userId,
      rez: 0,
      row: null,
      errors: [],
      show: false,
      showInfo: false,
      seimos: this.$store.state.visosSeimos,
    };
  },
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
    userKlase() {
      return this.$store.getters.kelintokas;
    },
    routerParams() {
      return this.$route.params.kl;
    },
    userRez() {
      return (
        (parseFloat(this.$store.state.userRezI) +
          parseFloat(this.$store.state.userRezII)) /
        2
      ).toFixed(2);
    },
    klausimaiTevam() {
      return this.$store.getters.getKlausimaiTevam(this.$route.params.kl);
    },
    komentarasMok() {
      return this.$store.getters.komentarasMokiniui;
    },
  },
  methods: {},
};
</script>
