<template>
  <v-container id="mano-klase" fluid>
    <loginSite v-show="!this.$store.state.isAuthenticated"></loginSite>

    <div justify="center" v-if="this.$store.state.isAuthenticated">
      <base-material-card :color="spalva">
        <template v-slot:heading>
          <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">
            Mano klasė {{ select?.name }}
          </div>
        </template>
        <v-row
          class="d-flex text-h6 text-md-h5 text-lg-h4 text-xl-h3 align-center"
        >
          <v-col cols="12" md="6" class="text-h6 d-flex justify-end">
            Pasirinkti klasę
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="select"
              :items="klase"
              item-text="name"
              item-value="id"
              label="Select"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </v-col>
        </v-row>
        <div class="display-1 font-weight-light">
          Vaikų sąrašas
          <h10 v-if="removedFromClass" class="text-center grey--text">
            Mokinys ištrintas iš {{ select?.name }} klasės
          </h10>
        </div>
        <v-simple-table fixed-header dense>
          <template v-slot:default>
            <thead justify="center">
              <tr>
                <th>Eil. Nr.</th>
                <th>Vardas Pavardė</th>
                <th>Elektroninis Paštas</th>
                <th>Aktyvuotas</th>
                <th>Pažymiai</th>
                <th>Redaguoti</th>
                <th class="text-center">Tėvai</th>
                <th class="text-center">Ištrinti</th>
                <th class="text-center">Aktyvacija</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, i) in getMokiniaiList" :key="i">
                <td>{{ 1 + i }}</td>
                <td>{{ item?.name }} {{ item?.surname }}</td>
                <td>{{ item?.email }}</td>
                <td>{{ item?.is_active ? "Taip" : "Ne" }}</td>
                <td><userDialog :source="item"></userDialog></td>
                <td class="text-center">
                    <v-dialog
                      v-model="item.dialog"
                      persistent
                      max-width="700px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="error"
                          elevation="2"
                          icon
                          x-small
                        >
                          <v-icon> mdi-account-edit-outline </v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="text-h5"
                            >Redaguoti Mokinio Informacija</span
                          >
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="item.name"
                                  label="Vardas"
                                  required
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  v-model="item.surname"
                                  label="Pavardė"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                  :value="item.email"
                                  label="Email*"
                                  readonly
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            :color="spalva"
                            text
                            @click="item.dialog = false"
                          >
                            Uždaryti
                          </v-btn>
                          <v-btn :color="spalva" text @click="editUser(item)">
                            Saugoti
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                </td>

                <td v-if="getTevas(item.id).length !== 0">
                  <div v-for="(tev, i) in getTevas(item.id)" :key="i">
                    {{ tev.parent.email }}
                  </div>
                </td>

                <td v-else>
                  <userParentsDialog :source="item"></userParentsDialog>
                </td>

                <td>
                  <v-dialog v-model="item.delDialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        elevation="2"
                        icon
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-minus-circle-outline </v-icon>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Ištrinti mokinį iš klasės
                      </v-card-title>

                      <v-card-text>
                        Ar tikrai norite ištrinti {{ item?.name }}
                        {{ item?.surname }}
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="item.delDialog = false"
                        >
                          Atsisakyti
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click.stop.prevent="removeUser(item.id)"
                        >
                          Trinti
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </td>

                <td>
                  <v-dialog v-model="item.resendDialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        elevation="2"
                        icon
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-plus-circle-outline </v-icon>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Pakartoti aktyvaciją
                      </v-card-title>

                      <v-card-text>
                        Ar tikrai norite pakartoti aktyvaciją {{ item?.name }}
                        {{ item?.email }}
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="item.resendDialog = false"
                        >
                          Atsisakyti
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click.stop.prevent="resend_activation(item.email)"
                        >
                          Siųsti
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </base-material-card>
    </div>
    <v-row
      class="pa-10"
      justify="center"
      v-if="this.$store.state.isAuthenticated"
    >
      <v-col cols="12" md="6" class="d-flex justify-end">
        <base-material-card :color="spalva">
          <template v-slot:heading>
            <div class="text-h6 text-md-h5 text-lg-h4 text-xl-h3">
              Pridėti naują mokinį į {{ select?.name }}
            </div>
          </template>
          <v-form @submit.prevent="addUser">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="name"
                    label="Vardas"
                    class="purple-input"
                    required
                    dense
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="surname"
                    label="Pavardė"
                    class="purple-input"
                    required
                    dense
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="password"
                    label="Naujas slaptažodis"
                    required
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show ? 'text' : 'password'"
                    hint="Mažiausiai 8 simboliai"
                    counter
                    @click:append="show = !show"
                    dense
                    color="blue"
                    autocomplete="false"
                    class="purple-input"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    required
                    dense
                    color="blue"
                    autocomplete="false"
                  />
                </v-col>
                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                    type="submit"
                    dark
                    :color="spalva"
                    class="mr-0"
                    @click.stop.prevent="addUser"
                  >
                    Įvesti naują mokinį
                  </v-btn>
                </v-col>
                <v-alert
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  v-if="errors.length"
                >
                  <p v-for="error in errors" :key="error.id">
                    {{ error }}
                  </p>
                </v-alert>
                <v-card-text v-if="registered">
                  <h4 class="text-center grey--text">
                    Mokinys {{ user.email }} uzregistruotas
                  </h4>
                </v-card-text>
                <v-card-text v-if="parentregistered">
                  <h4 class="text-center grey--text">
                    Mokinio {{ user.email }} tevas uzregistruotas
                  </h4>
                </v-card-text>
                <v-card-text v-if="registeredToClass">
                  <h4 class="text-center grey--text">
                    Mokinys {{ user.email }} uzregistruotas į klasę
                    {{ klase?.name }}
                  </h4>
                </v-card-text>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import baseMaterialCard from "@/components/base/MaterialCard.vue";
import loginSite from "@/components/LoginSite.vue";
import userDialog from "@/components/EditUserDialog.vue";
import userParentsDialog from "@/components/EditUserParentsDialog.vue";
import axios from "axios";

export default {
  name: "manoklase-view",
  components: {
    baseMaterialCard,
    loginSite,
    userDialog,
    userParentsDialog,
  },
  data() {
    return {
      dialog: false,
      select: this.$store.state.mokytojoKlase[0],
      klase: this.$store.state.mokytojoKlase,
      mokiniai: this.$store.state.mokiniai,
      user: "",
      userId: "",
      parentName: "",
      parentSurname: "",
      parentEmail: "",
      parentPassword: "",
      show: false,
      registered: false,
      parentregistered: false,
      registeredToClass: false,
      removedFromClass: false,
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail privalomas",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      rules: {
        required: (value) => !!value || "Privalomas",
        min: (v) => v.length >= 8 || "Min 8 simboliai",
      },
      email: "",
      password: "",
      name: "",
      surname: "",
      pazimys: "",
      is_first: true,
      errors: [],
    };
  },
  // https://vuex.vuejs.org/guide/actions.html#composing-actions
  computed: {
    spalva() {
      return this.$store.state.spalva;
    },
    getTevas() {
      return this.$store.getters.mokinioTevas;
    },
    getMokiniaiList() {
      console.log(
        "------------>",
        this.$store.getters.mokiniaiList(this.select.id)
      );

      return this.$store.getters.mokiniaiList(this.select.id);

      // return this.$store.state.mokiniai;
    },
    role() {
      return this.$store.state.userRole;
      // this.$router.go(); // Refreshes page
    },
  },
  methods: {
    async editUser(postData) {
      this.errors = [];
      this.$store.commit("setIsLoading", true);
      console.log("------------>", postData);

      await axios
        .patch(postData.url, postData)
        .then((response) => {
          console.log("edit User", response.data);
          this.registered = true;
          setTimeout(() => (this.registered = false), 10000);
          this.user = response.data;
          this.$store.dispatch("getMokiniai");
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
    async addUser() {
      this.$store.commit("setIsLoading", true);

      this.errors = [];
      const postData = {
        name: this.name,
        surname: this.surname,
        email: this.email.toLowerCase(),
        password: this.password,
      };
      await axios
        .post("/auth/users/", postData)
        .then((response) => {
          console.log("User added", response.data);
          this.registered = true;
          setTimeout(() => (this.registered = false), 10000);
          this.user = response.data;
          this.addUserToClass(response.data);
          // this.mokiniai = this.$store.state.mokiniai;
          // this.$router.go();
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
    async resend_activation(data) {
      this.$store.commit("setIsLoading", true);

      this.errors = [];
      const postData = {
        email: data,
      };
      await axios
        .post("/auth/users/resend_activation/", postData)
        .then((response) => {
          console.log("resend_activation-->", response.data);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
    async addUserToClass(mokinys) {
      console.log("addUserToClass --->Starting", this.select.id);

      this.errors = [];
      const postData = {
        id: mokinys.id,
        klase_id: this.select.id,
      };
      await axios
        .post("/addUserToClass/", postData)
        .then((response) => {
          console.log("addUserToClass --->", response);
          this.registeredToClass = true;
          setTimeout(() => (this.registeredToClass = false), 10000);
          this.$store.dispatch("getMokiniai");
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
    },
    async removeUser(userId) {
      this.$store.commit("setIsLoading", true);

      this.errors = [];
      const postData = {
        id: userId,
        klase_id: this.select.id,
      };
      await axios
        .post("/RemoveUserFromClass/", postData)
        .then((response) => {
          console.log("RemoveUserFromClass --->", response);
          this.removedFromClass = true;
          setTimeout(() => (this.removedFromClass = false), 10000);
          this.$store.dispatch("getMokiniai");
          this.dialog = false;
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              if (property == "non_field_errors") {
                this.errors.push(`Klaida: ${error.response.data[property]}`);
              } else {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          } else if (error.message) {
            this.errors.push("Something went wrong. Please try again!");
          }
          console.log("Erroras", JSON.stringify(error));
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
